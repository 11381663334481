import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Home'
import ConsumerLogin from './consumer/ConsumerLogin'
import Login from './manager/Login'
import Dashboard from './manager/Dashboard'
import Consumers from './manager/Consumers'
import ConsumerDashboard from './consumer/ConsumerDashboard'
import ConsumerProducts from './consumer/ConsumerProducts'
import DataCrud from './manager/dataCrud'
import ConsumerMyTicket from './consumer/ConsumerMyTicket'
import TicketAssign from './manager/TicketAssign'
import Datatable from './manager/Datatable'
import ServiceEngineers from './manager/ServiceEngineers'
import AllProducts from './manager/AllProducts.js'
import AllTickets from './manager/AllTickets'
import Testing from './consumer/Testing.js'
import TicketTest from './manager/TicketTest'

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Home />} />

        {/* SERVICE MANAGER */}
        <Route path="/manager/login" element={<Login />} />
        <Route path="/manager/dashboard" element={<Dashboard />} />
        <Route path="/manager/consumers" element={<Consumers />} />
        <Route path="/manager/tickets" element={<Login />} />
        <Route path="/manager/login" element={<Login />} />
        <Route path="/manager/ticketAssign" element={<TicketAssign />} />
        <Route path="/manager/datatable" element={<Datatable />} />
        <Route path="/manager/serviceEngineers" element={<ServiceEngineers />} />
        <Route path="/manager/allProducts" element={<AllProducts />} />
        <Route path="/manager/allTickets" element={<AllTickets />} />
        <Route path="/manager/ticketTest" element={<TicketTest />} />
       
        {/* CONSUMER */}
        <Route path="/consumer/login" element={<ConsumerLogin />} />
        <Route path="/consumer/dashboard" element={<ConsumerDashboard />} />
        <Route path="/consumer/products" element={<ConsumerProducts />} />
        <Route path="/consumer/crud" element={<DataCrud />} />
        <Route path="/consumer/myTicket" element={<ConsumerMyTicket />} />
        <Route path="/consumer/testing" element={<Testing />} />

      </Routes>
    </BrowserRouter>
  )
}

export default Routers