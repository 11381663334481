import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from './service/ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { PickList } from 'primereact/picklist';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

// Register custom filter
FilterService.register('custom_price', (value, filters) => {
    const [min, max] = filters ?? [null, null];
    if (min === null && max === null) return true;
    if (min !== null && max === null) return min <= value;
    if (min === null && max !== null) return value <= max;
    return min <= value && value <= max;
});

export default function ProductsDemo() {
    const emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        inventoryStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        price: { value: null, matchMode: FilterMatchMode.CUSTOM }
    });
    const [columns, setColumns] = useState([
        { field: 'code', header: 'Code' },
        { field: 'name', header: 'Name' },
        { field: 'image', header: 'Image' },
        { field: 'price', header: 'Price' },
        { field: 'category', header: 'Category' },
        { field: 'rating', header: 'Reviews' },
        { field: 'inventoryStatus', header: 'Status' }
    ]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [availableColumns, setAvailableColumns] = useState([]);
    const [pickListDialog, setPickListDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        ProductService.getProducts().then((data) => setProducts(data));
        // Initialize availableColumns and selectedColumns
        setAvailableColumns(columns);
        setSelectedColumns([]);
    }, [columns]);

    useEffect(() => {
        // Sync the available columns list with the selected columns
        const filteredAvailableColumns = columns.filter(col => !selectedColumns.some(selected => selected.field === col.field));
        setAvailableColumns(filteredAvailableColumns);
    }, [selectedColumns, columns]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };

            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            } else {
                _product.id = createId();
                _product.image = 'product-placeholder.svg';
                _products.push(_product);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);

        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));

        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    };

    const onCategoryChange = (e) => {
        let _product = { ...product };

        _product['category'] = e.value;
        setProduct(_product);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };

        _product[`${name}`] = val;

        setProduct(_product);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };

        _product[`${name}`] = val;

        setProduct(_product);
    };

    const openPickListDialog = () => {
        setPickListDialog(true);
    };

    const closePickListDialog = () => {
        setPickListDialog(false);
    };

    const onColumnsChange = (e) => {
        const newAvailableColumns = e.source.map(col => ({ field: col.field, header: col.header }));
        const newSelectedColumns = e.target.map(col => ({ field: col.field, header: col.header }));

        // Remove duplicates
        const uniqueAvailableColumns = newAvailableColumns.filter((col, index, self) =>
            index === self.findIndex((t) => (
                t.field === col.field
            ))
        );

        setAvailableColumns(uniqueAvailableColumns);
        setSelectedColumns(newSelectedColumns);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
                <Button label="Select Columns" icon="pi pi-cog" onClick={openPickListDialog} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-success" onClick={exportCSV} />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.inventoryStatus} severity={rowData.inventoryStatus === 'INSTOCK' ? 'success' : 'danger'} />;
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.name} className="product-image" />;
    };

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center">
            <h4 className="m-0">Manage Products</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );

    const priceFilterTemplate = (options) => {
        const [minPrice, maxPrice] = options.value ?? [null, null];
        return (
            <div className="flex gap-1">
                <InputNumber
                    value={minPrice}
                    onValueChange={(e) => options.filterApplyCallback([e.value, maxPrice])}
                    placeholder="Min Price"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-full"
                />
                <InputNumber
                    value={maxPrice}
                    onValueChange={(e) => options.filterApplyCallback([minPrice, e.value])}
                    placeholder="Max Price"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-full"
                />
            </div>
        );
    };

    const ratingFilterTemplate = (options) => {
        const [minRating, maxRating] = options.value ?? [null, null];
        return (
            <div className="flex gap-1">
                <InputNumber
                    value={minRating}
                    onValueChange={(e) => options.filterApplyCallback([e.value, maxRating])}
                    placeholder="Min Rating"
                    className="w-full"
                />
                <InputNumber
                    value={maxRating}
                    onValueChange={(e) => options.filterApplyCallback([minRating, e.value])}
                    placeholder="Max Rating"
                    className="w-full"
                />
            </div>
        );
    };

    const columnPickerDialog = (
        <Dialog header="Select Columns" visible={pickListDialog} style={{ width: '50vw' }} onHide={closePickListDialog}>
            <PickList
                source={availableColumns}
                target={selectedColumns}
                itemTemplate={(item) => item.header}
                onChange={onColumnsChange}
            />
        </Dialog>
    );

    return (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
            <DataTable
                ref={dt}
                value={products}
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                globalFilter={globalFilter}
                filters={filters}
                filterDisplay="row"
                header={header}
            >
                {selectedColumns.map((col) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={col.field === 'image' ? imageBodyTemplate
                            : col.field === 'price' ? priceBodyTemplate
                            : col.field === 'rating' ? ratingBodyTemplate
                            : col.field === 'inventoryStatus' ? statusBodyTemplate
                            : null}
                        sortable
                        filter
                        filterPlaceholder={`Search by ${col.header}`}
                        filterField={col.field}
                        filterElement={col.field === 'price' ? priceFilterTemplate
                            : col.field === 'rating' ? ratingFilterTemplate
                            : null}
                        style={{ minWidth: '12rem' }}
                    />
                ))}
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>

            {/* Product Dialog */}
            <Dialog visible={productDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={<>
                <Button label="Cancel" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
            </>} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} />
                    {submitted && !product.name && <small className="p-invalid">Name is required.</small>}
                </div>
                
                <div className="field">
                    <label htmlFor="price">Price</label>
                    <InputNumber id="price" value={product.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="currency" currency="USD" locale="en-US" />
                </div>
                <div className="field">
                    <label htmlFor="rating">Rating</label>
                    <InputNumber id="rating" value={product.rating} onValueChange={(e) => onInputNumberChange(e, 'rating')} />
                </div>
                
            </Dialog>

            {/* Delete Product Confirmation */}
            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={<>
                <Button label="No" icon="pi pi-times" onClick={hideDeleteProductDialog} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={deleteProduct} />
            </>} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                </div>
            </Dialog>

            {/* Delete Products Confirmation */}
            <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={<>
                <Button label="No" icon="pi pi-times" onClick={hideDeleteProductsDialog} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={deleteSelectedProducts} />
            </>} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {selectedProducts && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog>

            {/* Column Picker Dialog */}
            {columnPickerDialog}
        </>
    );
}
