import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { PickList } from 'primereact/picklist';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Product } from './service/Product';
import ConsumerBase from './ConsumerBase';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import './styles.css';

const ConsumerProducts = () => {
    const emptyProduct = {
        id: null,
        productId: null,
        modelId: null,
        productImages: '',
    };

    const [product, setProduct] = useState(emptyProduct);
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [reorderColumnsDialog, setReorderColumnsDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(true);
    const [availableColumns, setAvailableColumns] = useState([
        { label: "Product ID", id: "id" },
        { label: "Product Name", id: "productName" },
        { label: "Model Name", id: "modelName" },
        
    ]);
    const [selectedColumns, setSelectedColumns] = useState(availableColumns);
    const toast = useRef(null);
    const dt = useRef(null);

    const toggleFilters = () => {
        setFiltersVisible((prevState) => !prevState);
      };

      const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        modelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        
      });

    useEffect(() => {
        Product.getProducts().then((data) => setProducts(data));
    }, []);

    const handleColumnChange = (e) => {
        setAvailableColumns(e.source);
        setSelectedColumns(e.target);
    };

    const saveProduct = () => {
        setSubmitted(true);
        let _products = [...products];
        let _product = { ...product };

        if (product.id) {
            const index = _products.findIndex(t => t.id === product.id);
            _products[index] = _product;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        } else {
            _product.id = new Date().getTime(); // Unique ID generator
            _products.push(_product);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        }

        setProducts(_products);
        setProductDialog(false);
        setProduct(emptyProduct);
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <img
                src={`/assets/images/${rowData.productImages}`}
                alt={rowData.productImages}
                className="shadow-2 border-round"
                style={{ width: '64px' }}
            />
        );
    };

    const deleteProduct = () => {
        let _products = products.filter(val => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts([]);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Selected Products Deleted', life: 3000 });
    };

    const onSelectionChange = (e) => {
        setSelectedProducts(e.value);
    };

    const editProduct = (rowData) => {
        setProduct({ ...rowData });
        setProductDialog(true);
    };

    const rowActionsBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button 
                    icon="pi pi-pencil" 
                    className="custom-button-edit p-button-rounded p-button-sm" 
                    onClick={() => editProduct(rowData)} 
                />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-danger p-button-rounded p-button-sm" 
                    onClick={() => {
                        setProduct(rowData);
                        setDeleteProductDialog(true);
                    }} 
                />
            </div>
        );
    };

    const openReorder = () => {
        setReorderColumnsDialog(true);
    };

    const header = (
        <div className="flex flex-wrap gap-2 justify-between items-center">
            <h4 className="m-0">My Products</h4>
            <div className="flex gap-2">
                <Button 
                    label="Export" 
                    icon="pi pi-upload" 
                    className="button-export" 
                    onClick={() => dt.current.exportCSV()} 
                />
                
                <Button className="button-filters" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </Button>
            </div>
        </div>
    );

    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancel"
                icon="pi pi-times"
                outlined
                onClick={() => setProductDialog(false)}
                className="cancel-button"
            />
            <Button
                label="Save"
                icon="pi pi-check"
                onClick={saveProduct}
                className="save-button"
            />
        </React.Fragment>
    );

    const reorderColumnsDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={() => setReorderColumnsDialog(false)} />
            <Button label="Save" icon="pi pi-check" onClick={() => setReorderColumnsDialog(false)} />
        </React.Fragment>
    );

    const onInputChange = (e, field) => {
        const val = e.target.value;
        setProduct({ ...product, [field]: val });
    };

    return (
        <ConsumerBase>
            <Toast ref={toast} />
            <DataTable 
                ref={dt} 
                value={products} 
                selection={selectedProducts} 
                onSelectionChange={onSelectionChange} 
                dataKey="id" 
                paginator 
                rows={10} 
                rowsPerPageOptions={[10, 20, 30]} 
                header={header} 
                globalFilter={globalFilterValue}
                filters={filtersVisible ? filters : null}
                filterDisplay={filtersVisible ? "row" : "none"}
                emptyMessage="No Product found."
            >   
                {selectedColumns.map(col => (
                    <Column 
                        key={col.id} 
                        field={col.id} 
                        header={col.label} 
                        sortable 
                        filter
                        filterPlaceholder="Search"
                        
                    />
                ))}
                <Column
                    body={imageBodyTemplate}
                    header="Product Image"
                    style={{ width: '100px' }}
                />
            </DataTable>

            <Dialog 
                visible={reorderColumnsDialog} 
                style={{ width: '700px' }} 
                header="Reorder Columns" 
                modal 
                footer={reorderColumnsDialogFooter} 
                onHide={() => setReorderColumnsDialog(false)}
            >
                <PickList 
                    source={availableColumns} 
                    target={selectedColumns} 
                    onChange={handleColumnChange} 
                    itemTemplate={(item) => item.label} 
                    sourceHeader="Available Columns" 
                    targetHeader="Selected Columns" 
                />
            </Dialog>
        </ConsumerBase>
    );
};

export default ConsumerProducts;