import Base from "./Base";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ServiceEngineer } from "./service/ServiceEngineer";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea"; // Corrected import
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import "./styles.css"; //
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const ServiceEngineers = () => {
  const emptyServiceEngineer = {
    id: null,
    seId: null,
    productId: null,
    modelId: null,
    firstName: "",
    lastName: "",
    dob: null,
    gender: "",
    phoneNo: null,
    emailId: "",
    qualification: "",
    address: "",
    categories: [], // Update to array
    areas: [], // Update to array
    password: "",
    confirmPassword: "",
    createDateAndTime: null,
    updateDateAndTime: null,
  };
  const [serviceEngineer, setServiceEngineer] = useState(emptyServiceEngineer);
  
  const [selectedDescription, setSelectedDescription] = useState(
    serviceEngineer.serviceEngineerDescription
  );
  const [serviceEngineers, setServiceEngineers] = useState([]);
  const [serviceEngineerDialog, setServiceEngineerDialog] = useState(false);
  const [deleteServiceEngineerDialog, setDeleteServiceEngineerDialog] =
    useState(false);
  const [deleteServiceEngineersDialog, setDeleteServiceEngineersDialog] =
    useState(false);
  const [reorderColumnsDialog, setReorderColumnsDialog] = useState(false);
  const [selectedServiceEngineers, setSelectedServiceEngineers] = useState([]);
  const [selectedImage, setSelectedImage] = useState("/assets/images/logo.png"); // Default image path
  const [submitted, setSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    serviceEngineer.selectedCountry || ""
  );
  const [selectedState, setSelectedState] = useState(
    serviceEngineer.selectedState || ""
  );
  const [selectedCity, setSelectedCity] = useState(
    serviceEngineer.selectedCity || ""
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const serviceEngineerRemark = serviceEngineer.serviceEngineerRemark || "";
  const [globalFilter, setGlobalFilter] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [date, setDate] = useState(null);
  const [filtersVisible, setFiltersVisible] = useState(true);

  const toggleFilters = () => {
    setFiltersVisible((prevState) => !prevState);
  };

  const categories = [
    { name: "Category 1", id: "C1" },
    { name: "Category 2", id: "C2" },
    { name: "Category 3", id: "C3" },
    { name: "Category 4", id: "C4" },
    { name: "Category 5", id: "C5" },
  ];

  const areas = [
    { name: "Area 1", id: "A1" },
    { name: "Area 2", id: "A2" },
    { name: "Area 3", id: "A3" },
    { name: "Area 4", id: "A4" },
    { name: "Area 5", id: "A5" },
  ];

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const models = selectedProducts.flatMap((productName) => {
        const product = productdata.products.find(
          (p) => p.name === productName
        );
        return product ? product.models : [];
      });
      console.log("Available Models:", models); // Debug log
      setAvailableModels(models);
    } else {
      setAvailableModels([]);
    }
  }, [selectedProducts]);

  useEffect(() => {
    // Load cities when selectedState changes
    if (selectedProducts) {
      const modelsData = models.find(
        (models) => models.name === selectedModels
      );
    }
  }, [selectedModel, models]);

  useEffect(() => {
    // Load states when selectedCountry changes
    if (selectedCountry) {
      const countryData = data.countries.find(
        (country) => country.name === selectedCountry
      );
      setStates(countryData ? countryData.states : []);
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Load cities when selectedState changes
    if (selectedState) {
      const stateData = states.find((state) => state.name === selectedState);
      setCities(stateData ? stateData.cities : []);
    }
  }, [selectedState, states]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    seId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    gender: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    emailId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phoneNo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    qualification: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCountry: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedState: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    categories: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    areas: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    password: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    confirmPassword: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [availableColumns, setAvailableColumns] = useState([
    { label: "Service Engineer ID", id: "seId" },
    { label: "First Name", id: "firstName" },
    { label: "Last Name", id: "lastName" },
    { label: "Email Id", id: "emailId" },
    { label: "Gender", id: "gender" },
    { label: "Phone No", id: "phoneNo" },
    { label: "Qualification", id: "qualification" },
    { label: "Address", id: "address" },
    { label: "Country", id: "selectedCountry" },
    { label: "State", id: "selectedState" },
    { label: "City", id: "selectedCity" },
    { label: "Service Category", id: "categories" },
    { label: "Service Area", id: "areas" },
    { label: "Password", id: "password" },
    { label: "Confirm Password", id: "confirmPassword" },
  ]);

  const [selectedColumns, setSelectedColumns] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    ServiceEngineer.getServiceEngineers().then((data) =>
      setServiceEngineers(data)
    );
    setSelectedColumns(availableColumns);
    setSource(availableColumns);
  }, []);

  const onDateChange = (e, fieldName) => {
    setServiceEngineer({
      ...serviceEngineer,
      [fieldName]: e.value,
    });
  };

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDescriptionChange = (e) => {
    setSelectedDescription(e.value);
    onInputChange({ target: { value: e.value } }, "ticketDescription");
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setServiceEngineer((prevServiceEngineer) => ({
      ...prevServiceEngineer,
      selectedCountry: e.target.value, // Update the productImage field in the ticket state
    }));

    setSelectedState(""); // Reset state and city when country changes
    setSelectedCity("");
  };

  const handleUpload = (e) => {
    if (e.files && e.files.length > 0) {
      const imageUrl = URL.createObjectURL(e.files[0]); // Get the selected image URL
      setServiceEngineer((prevServiceEngineer) => ({
        ...prevServiceEngineer,
        productImage: imageUrl, // Update the productImage field in the ticket state
      }));
    }
    onInputChange(
      { target: { id: "productImages", value: e.files } },
      "productImages"
    );
  };

  const handleInputChange = (e, field) => {
    setServiceEngineer((prevServiceEngineer) => ({
      ...prevServiceEngineer,
      [field]: e.target.value, // Update other fields in the ticket state
    }));
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);

    setServiceEngineer((prevServiceEngineer) => ({
      ...prevServiceEngineer,
      selectedState: e.target.value, // Update the productImage field in the ticket state
    }));
    setSelectedCity(""); // Reset city when state changes
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);

    setServiceEngineer((prevServiceEngineer) => ({
      ...prevServiceEngineer,
      selectedCity: e.target.value, // Update the productImage field in the ticket state
    }));
  };
  const handleCategoryChange = (e) => {
    // Extract the selected category objects from the MultiSelect
    const selectedCategories = e.value;
  
    // Update the selectedCategory state
    setSelectedCategory(selectedCategories);
  
    // Map the selected categories to the format expected by setServiceEngineer
    const updatedCategories = selectedCategories.map(category => ({ name: category.name }));
  
    // Update the service engineer state
    setServiceEngineer(prevServiceEngineer => ({
      ...prevServiceEngineer,
      categories: updatedCategories,
    }));
  };

  const handleAreaChange = (e) => {
    const selectedAreas = e.value;
  
    // Update the selectedArea state
    setSelectedArea(selectedAreas);
  
    // Map the selected areas to the format expected by setServiceEngineer
    const updatedAreas = selectedAreas.map(area => ({ name: area.name }));
  
    // Update the service engineer state
    setServiceEngineer(prevServiceEngineer => ({
      ...prevServiceEngineer,
      areas: updatedAreas,
    }));
  };
  

  const handleColumnChange = (e) => {
    setSource(e.source);
    setTarget(e.target);
  };

  const saveServiceEngineer = () => {
    setSubmitted(true);
    console.log(serviceEngineer)
    let _serviceEngineers = [...serviceEngineers];
    let _serviceEngineer = { ...serviceEngineer };

    if (serviceEngineer.id) {
      const index = _serviceEngineers.findIndex(
        (t) => t.id === serviceEngineer.id
      );
      _serviceEngineers[index] = _serviceEngineer;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Service Engineer Updated",
        life: 3000,
      });
    } else {
      _serviceEngineer.id = new Date().getTime(); // Unique ID generator
      _serviceEngineers.push(_serviceEngineer);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Service Engineer Created",
        life: 3000,
      });
    }

    setServiceEngineers(_serviceEngineers);
    setServiceEngineerDialog(false);
    setServiceEngineer(emptyServiceEngineer);
    setSelectedCountry(emptyServiceEngineer);
    setSelectedState(emptyServiceEngineer);
   
    

  };

  const hideDialog = () => {
    setServiceEngineerDialog(false);
    setServiceEngineer(emptyServiceEngineer);
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setDate("");
    setSelectedCategory([]);
    setSelectedArea([]);
  };

  const handleProductChange = (e) => {
    setSelectedProducts(e.value);
  };

  const handleModelChange = (e) => {
    setSelectedModels(e.value);
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`/assets/images/${rowData.productImages}`}
        alt={rowData.productImages}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };
  const deleteServiceEngineer = () => {
    let _serviceEngineers = serviceEngineers.filter(
      (val) => val.id !== serviceEngineer.id
    );
    setServiceEngineers(_serviceEngineers);
    setDeleteServiceEngineerDialog(false);
    setServiceEngineer(emptyServiceEngineer);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Service Engineer Deleted",
      life: 3000,
    });
  };

  const deleteSelectedServiceEngineers = () => {
    let _serviceEngineers = serviceEngineers.filter(
      (val) => !selectedServiceEngineers.includes(val)
    );
    setServiceEngineers(_serviceEngineers);
    setDeleteServiceEngineersDialog(false);
    setSelectedServiceEngineers([]);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Service Engineer Deleted",
      life: 3000,
    });
  };

  const onSelectionChange = (e) => {
    setSelectedServiceEngineers(e.value);
  };

  const editServiceEngineer = (rowData) => {
    setServiceEngineer({ ...rowData });
    setSelectedCountry(rowData.selectedCountry || "");
    setSelectedState(rowData.selectedState || "");
    setSelectedCity(rowData.selectedCity || "");
    setSelectedCategory(rowData.categories || []); // Ensure array fields are handled
  setSelectedArea(rowData.areas || []); // Ensure array fields are handled
    setServiceEngineerDialog(true);
  };
  const data = {
    countries: [
      {
        name: "India",
        states: [
          { name: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
          { name: "Karnataka", cities: ["Bengaluru", "Mysuru"] },
        ],
      },
      {
        name: "USA",
        states: [
          { name: "California", cities: ["Los Angeles", "San Francisco"] },
          { name: "New York", cities: ["New York City", "Buffalo"] },
        ],
      },
    ],
  };

  const productdata = {
    products: [
      {
        name: "Product A",
        models: [{ name: "ModelA123" }, { name: "ModelB234" }],
      },
      {
        name: "Product B",
        models: [{ name: "ModelC345" }, { name: "ModelD456" }],
      },
    ],
  };

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="custom-button-edit p-button-rounded p-button-sm"
          onClick={() => editServiceEngineer(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="custom-button-delete p-button-rounded p-button-sm"
          onClick={() => {
            setServiceEngineer(rowData);
            setDeleteServiceEngineerDialog(true);
          }}
        />
      </div>
    );
  };

  const openReorder = () => {
    setReorderColumnsDialog(true);
  };

  const newServiceEngineer = () => {
    setServiceEngineer(emptyServiceEngineer);
    setServiceEngineerDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          className="button-new"
          onClick={newServiceEngineer}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="button-delete"
          onClick={() => setDeleteServiceEngineersDialog(true)}
          disabled={!selectedServiceEngineers.length} // Disable if no tickets selected
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="button-export mx-2"
          onClick={() => dt.current.exportCSV()}
        />
        <Button
          label="Reorder Columns"
          icon="pi pi-sort"
          className="button-reorder mx-2"
          onClick={openReorder}
        />

<Button className="button-filters" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
    );
  };

  const header = (
    <div className="flex items-center justify-between gap-2">
      <h4 className="m-0">Service Engineers</h4>
      <div className="flex items-center p-input-icon p-input-icon-left">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilterValue(e.target.value)}
          placeholder="Search..."
          className="ml-2"
        />
      </div>
    </div>
  );

  const serviceEngineerDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => hideDialog(false)}
        className="cancel-button" // Apply CSS class for Cancel button
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={saveServiceEngineer}
        className="edit-button" // Apply CSS class for Edit button
      />
    </React.Fragment>
  );

  const deleteServiceEngineerDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteServiceEngineerDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteServiceEngineer} />
    </React.Fragment>
  );

  const deleteServiceEngineersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteServiceEngineersDialog(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={deleteSelectedServiceEngineers}
      />
    </React.Fragment>
  );
  // ===================================SAVE REORDER COMPONENTS==========================================
  const saveReorderColumn = () => {
    setSelectedColumns(target);
    setReorderColumnsDialog(false);
  };

  const reorderColumnsDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => setReorderColumnsDialog(false)}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveReorderColumn} />
    </React.Fragment>
  );
  // ===================================END OF SAVE REORDER COMPONENTS==========================================
  const onInputChange = (e, field) => {
    const val = e.target.value;
    setServiceEngineer({ ...serviceEngineer, [field]: val });
  };

  // ===================================VIEW COMPONENTS==========================================
  return (
    <Base>
      <div>
        <Toast ref={toast} />

        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />

        {selectedColumns && selectedColumns.length > 0 ? (
          <DataTable
            ref={dt}
            value={serviceEngineers.map((serviceEngineer) => ({
              ...serviceEngineer,
            }))}
            selection={selectedServiceEngineers}
            onSelectionChange={onSelectionChange}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            header={header}
            globalFilter={globalFilterValue}
            filters={filtersVisible ? filters : null}
            filterDisplay={filtersVisible ? "row" : "none"}
            emptyMessage="No ServiceEngineer found."
          >
            <Column selectionMode="multiple" exportable={false} />
           
{selectedColumns.map((col) => (
           <Column
             key={col.id}
             field={col.id}
             header={col.label}
             sortable
             filter
             filterPlaceholder="Search"
             style={{ minWidth: "12rem", textAlign: "left" }}
             body={(rowData) => {
               if (col.id === 'categories') {
                 return (
                   <div>
                     {rowData.categories.map((category, index) => (
                       <div key={index}>{category.name}</div>
                     ))}
                   </div>
                 );
               }
               if (col.id === 'areas') {
                return (
                  <div>
                    {rowData.areas.map((area, index) => (
                      <div key={index}>{area.name}</div>
                    ))}
                  </div>
                );
              }
               return rowData[col.id];
             }}
           />
         ))}

            <Column
              body={rowActionsBodyTemplate}
              exportable={false}
              className="mx-2"
              style={{ minWidth: "12rem" }}
            />
          </DataTable>
        ) : (
          <div className="card">
            <h4 style={{ textAlign: "center", padding: "1rem" }}>
              No Columns have been selected{" "}
            </h4>
          </div>
        )}

        <Dialog
          visible={serviceEngineerDialog}
          style={{ width: "700px" }}
          header={
            serviceEngineer.id
              ? "Edit Service Engineer Details"
              : "Create Service Engineer Details"
          }
          modal
          className="p-fluid dialog custom-dialog-header"
          footer={serviceEngineerDialogFooter}
          onHide={() => hideDialog(false)}
        >
          <div className="form-grid">
            <div className="field">
              <label htmlFor="firstName" className="bold-label">
                First Name
              </label>
              <InputText
                id="productId"
                value={serviceEngineer.firstName}
                onChange={(e) => onInputChange(e, "firstName")}
              />
            </div>

            <div className="field">
              <label htmlFor="lastName" className="bold-label">
                Last Name
              </label>
              <InputText
                id="productId"
                value={serviceEngineer.lastName}
                onChange={(e) => onInputChange(e, "lastName")}
              />
            </div>

            <div className="field">
              <label htmlFor="dob" className="bold-label">
                DOB
              </label>
              <Calendar
                id="buttondisplay"
                value={date}
                onChange={(e) => setDate(e.value)}
                showIcon
              />
            </div>

            <div className="field">
              <label htmlFor="gender" className="bold-label">
                Gender
              </label>
              <InputText
                id="productId"
                value={serviceEngineer.gender}
                onChange={(e) => onInputChange(e, "gender")}
              />
            </div>

            <div className="field">
              <label htmlFor="emailId" className="bold-label">
                Email Id
              </label>
              <InputText
                id="emailId"
                value={serviceEngineer.emailId}
                onChange={(e) => onInputChange(e, "emailId")}
              />
            </div>

            <div className="field">
              <label htmlFor="phoneNo" className="bold-label">
                Phone No
              </label>
              <InputText
                id="phoneNo"
                value={serviceEngineer.phoneNo}
                onChange={(e) => onInputChange(e, "phoneNo")}
              />
            </div>

            <div className="field">
              <label htmlFor="qualification" className="bold-label">
                Qualification
              </label>
              <InputText
                id="qualification"
                value={serviceEngineer.qualification}
                onChange={(e) => onInputChange(e, "qualification")}
              />
            </div>

            <div className="field">
              <label htmlFor="address" className="bold-label">
                Address
              </label>
              <InputText
                id="address"
                value={serviceEngineer.address}
                onChange={(e) => onInputChange(e, "address")}
              />
            </div>

            <div className="field">
              <label htmlFor="country" className="bold-label">
                Country
              </label>
              <select
                id="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {data.countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="field">
              <label htmlFor="state" className="bold-label">
                State
              </label>
              <select
                id="state"
                value={selectedState}
                onChange={handleStateChange}
                disabled={!selectedCountry}
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="field">
            <label htmlFor="city" className="bold-label">
              City
            </label>
            <select
              id="city"
              value={selectedCity}
              onChange={handleCityChange}
              disabled={!selectedState}
            >
              <option value="">Select City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>

          <div className="form-grid">
            <div className="field">
              <label htmlFor="categories" className="bold-label">
                Service Category
              </label>
              <MultiSelect
                id="selectedCategory"
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={categories}
                optionLabel="name"
                placeholder="Select Category"
                maxSelectedLabels={3}
                className="w-full md:w-"
              />
            </div>

            <div className="field">
              <label htmlFor="serviceArea" className="bold-label">
                Service Area
              </label>
              <MultiSelect
      id="selectedArea"
      value={selectedArea}
      onChange={handleAreaChange}
      options={areas}
      optionLabel="name"
      placeholder="Select Area"
      maxSelectedLabels={3}
      className="w-full md:w-"
    />
            </div>

            <div className="field">
              <label htmlFor="password" className="bold-label">
                Password
              </label>
              <Password
                id="password"
                value={serviceEngineer.password}
                onChange={(e) => onInputChange(e, "password")}
                toggleMask
              />
            </div>

            <div className="field">
              <label htmlFor="confirmPassword" className="bold-label">
                Confirm Password
              </label>
              <Password
                id="password"
                value={serviceEngineer.confirmPassword}
                onChange={(e) => onInputChange(e, "confirmPassword")}
                toggleMask
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteServiceEngineerDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteServiceEngineerDialogFooter}
          onHide={() => setDeleteServiceEngineerDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {serviceEngineer && (
              <span>
                Are you sure you want to delete serviceEngineers{" "}
                <b>{serviceEngineer.serviceEngineerRaiseUserId}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteServiceEngineersDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteServiceEngineersDialogFooter}
          onHide={() => setDeleteServiceEngineersDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to delete the selected Service Engineers?
            </span>
          </div>
        </Dialog>

        <Dialog
          visible={reorderColumnsDialog}
          style={{ width: "700px" }}
          header="Reorder Columns"
          modal
          footer={reorderColumnsDialogFooter}
          onHide={() => setReorderColumnsDialog(false)}
        >
          <PickList
            source={source}
            target={target}
            onChange={handleColumnChange}
            itemTemplate={(item) => item.label}
            sourceHeader="Available Columns"
            targetHeader="Selected Columns"
          />
        </Dialog>
      </div>
    </Base>
  );
};

export default ServiceEngineers;
