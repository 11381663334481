import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Draggable } from '@fullcalendar/interaction';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { Calendar } from "primereact/calendar";
import Base from './Base';
import "./styles.css"; //

// Sample data
const engineers = [
  { id: 1, name: 'Alice', events: [
      { title: 'Ticket 1', date: '2024-08-11', consumerId: '101', productName: 'Ac', modelName: 'ABC123', ticketDescription: 'Noise problem', ticketRemark: 'Noise problem', consumerAvailableContactDateAndTime: '08/12/2024 16:23', address: '123 Maple Street, New York, NY', country: 'India', state: 'Tamil Nadu', city: 'Coimbatore' },
      { title: 'Ticket 2', date: '2024-08-13', consumerId: '102', productName: 'Ac', modelName: 'ABC123', ticketDescription: 'Noise problem', ticketRemark: 'Noise problem', consumerAvailableContactDateAndTime: '08/12/2024 16:23', address: '123 Maple Street, New York, NY', country: 'India', state: 'Tamil Nadu', city: 'Coimbatore' }
    ]
  },
  { id: 2, name: 'Bob', events: [
      { title: 'Ticket 3', date: '2024-08-17', consumerId: '103', productName: 'Ac', modelName: 'ABC123', ticketDescription: 'Noise problem', ticketRemark: 'Noise problem', consumerAvailableContactDateAndTime: '08/12/2024 16:23', address: '123 Maple Street, New York, NY', country: 'India', state: 'Tamil Nadu', city: 'Coimbatore' },
      { title: 'Ticket 4', date: '2024-08-11', consumerId: '104', productName: 'Ac', modelName: 'ABC123', ticketDescription: 'Noise problem', ticketRemark: 'Noise problem', consumerAvailableContactDateAndTime: '08/12/2024 16:23', address: '123 Maple Street, New York, NY', country: 'India', state: 'Tamil Nadu', city: 'Coimbatore' }
    ]
  }
];

const data = {
  countries: [
    {
      name: "India",
      states: [
        { name: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
        { name: "Karnataka", cities: ["Bengaluru", "Mysuru"] },
      ],
    },
    {
      name: "USA",
      states: [
        { name: "California", cities: ["Los Angeles", "San Francisco"] },
        { name: "New York", cities: ["New York City", "Buffalo"] },
      ],
    },
  ],
};



const TicketAssign = () => {
  const calendarRef = useRef(null);
  const externalEventsRef = useRef(null);
  const [selectedEngineer, setSelectedEngineer] = useState(engineers[0]?.id || 1);
  const [events, setEvents] = useState(engineers[0]?.events || []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
const [selectedState, setSelectedState] = useState('');
const [selectedCity, setSelectedCity] = useState('');
const [states, setStates] = useState([]);
const [cities, setCities] = useState([]);
const [isHovered, setIsHovered] = useState(false);
  const [tickets, setTickets] = useState([
    { id: 1, title: 'Ticket 1', color: 'grey', consumerId: '101', productName: 'Ac', modelName: 'ABC123', ticketDescription: 'Noise problem', ticketRemark: 'Noise problem', consumerAvailableContactDateAndTime: '08/12/2024 16:23', address: '123 Maple Street, New York, NY', country: 'India', state: 'Tamil Nadu', city: 'Coimbatore' },
    { id: 2, title: 'Ticket 2', color: 'grey', consumerId: '102', productName: 'Fridge', modelName: 'XYZ456', ticketDescription: 'Cooling issue', ticketRemark: 'Check compressor', consumerAvailableContactDateAndTime: '08/13/2024 10:00', address: '456 Oak Avenue, Los Angeles, CA', country: 'USA', state: 'California', city: 'Los Angeles' },
    { id: 3, title: 'Ticket 3', color: 'grey', consumerId: '103', productName: 'Washing Machine', modelName: 'DEF789', ticketDescription: 'Water leakage', ticketRemark: 'Check pipe connections', consumerAvailableContactDateAndTime: '08/14/2024 14:30', address: '789 Pine Road, Chicago, IL', country: 'USA', state: 'Illinois', city: 'Chicago' }
  ]);
  const [newTicket, setNewTicket] = useState({
    title: '',
    consumerId: '',
    firstName: '',
    lastName: '',
    productName: [],
    modelName: [],
    ticketDescription: '',
    ticketRemark: '',
    consumerAvailableContactDateAndTime: '',
    address: '',
    country: '',
    state: '',
    city: ''
  });

  useEffect(() => {
    if (selectedCountry) {
      const countryData = data.countries.find(
        (country) => country.name === selectedCountry
      );
      setStates(countryData ? countryData.states : []);
      setSelectedState(''); // Reset state and city when country changes
      setSelectedCity('');
    }
  }, [selectedCountry]);
  
  useEffect(() => {
    if (selectedState) {
      const stateData = states.find((state) => state.name === selectedState);
      setCities(stateData ? stateData.cities : []);
      setSelectedCity(''); // Reset city when state changes
    }
  }, [selectedState, states]);


  useEffect(() => {
    if (externalEventsRef.current) {
      new Draggable(externalEventsRef.current, {
        itemSelector: '.fc-event',
        eventData: (eventEl) => ({
          title: eventEl.innerText,
        }),
      });
    }
  }, []);

  useEffect(() => {
    const selectedEngineerData = engineers.find(e => e.id === selectedEngineer);
    if (selectedEngineerData) {
      setEvents(selectedEngineerData.events);
    }
  }, [selectedEngineer]);

  const handleEventReceive = (info) => {
    const event = {
      title: info.event.title,
      date: info.event.startStr,
      consumerId: info.event.extendedProps.consumerId,
      productName: info.event.extendedProps.productName,
      modelName: info.event.extendedProps.modelName,
      ticketDescription: info.event.extendedProps.ticketDescription,
      ticketRemark: info.event.extendedProps.ticketRemark,
      consumerAvailableContactDateAndTime: info.event.extendedProps.consumerAvailableContactDateAndTime,
      address: info.event.extendedProps.address,
      country: info.event.extendedProps.country,
      state: info.event.extendedProps.state,
      city: info.event.extendedProps.city,
    };
    setEvents([...events, event]);

    // Remove the ticket from the panel after being dropped
    const updatedTickets = tickets.filter(ticket => ticket.title !== info.event.title);
    setTickets(updatedTickets);
  };

  const handleProductChange = (selectedOptions) => {
    setNewTicket({
      ...newTicket,
      productName: selectedOptions,
    });
  };

  const handleModelChange = (selectedOptions) => {
    setNewTicket({
      ...newTicket,
      modelName: selectedOptions,
    });
  };

  const productOptions = [
    { value: 'product1', label: 'Product 1' },
    { value: 'product2', label: 'Product 2' },
    { value: 'product3', label: 'Product 3' },
    
  ];

   // Sample model options
   const modelOptions = [
    { value: 'model1', label: 'Model 1' },
    { value: 'model2', label: 'Model 2' },
    { value: 'model3', label: 'Model 3' },
    
  ];

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
    setSelectedTicket(null);
  };

  const handleEngineerChange = (event) => {
    setSelectedEngineer(parseInt(event.target.value, 10));
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setModalIsOpen(true);
  };

  const handleAddTicket = () => {
    setSelectedEvent(null);
    setSelectedTicket(null);
    setModalIsOpen(true);
  };

  const handleNewTicketChange = (e) => {
    const { name, value } = e.target;
    setNewTicket(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddNewTicketSubmit = () => {
    const newTicketId = tickets.length ? Math.max(tickets.map(t => t.id)) + 1 : 1;
    const ticket = { id: newTicketId, color: 'grey', ...newTicket };
    setTickets([...tickets, ticket]);
    setEvents([...events, { title: ticket.title, date: new Date().toISOString().split('T')[0], ...ticket }]);
    setModalIsOpen(false);
    setNewTicket({
      title: '',
      consumerId: '',
      ticketId: '',
      lastName: '',
      productName: '',
      modelName: '',
      ticketDescription: '',
      ticketRemark: '',
      consumerAvailableContactDateAndTime: '',
      address: '',
      country: '',
      state: '',
      city: ''
    });
  };

  return (
    <Base>
      <div style={{ display: 'flex' }}>
        <div
          ref={externalEventsRef}
          id="external-events"
          style={{
            width: '200px',
            padding: '10px',
            borderRight: '1px solid #ddd',
            boxSizing: 'border-box'
          }}
        >
          <h3>Open Tickets</h3>
          {tickets.map(ticket => (
            <div
              key={ticket.id}
              className="fc-event"
              style={{ margin: '5px', padding: '10px', background: ticket.color, color: 'white', cursor: 'pointer' }}
              onClick={() => handleTicketClick(ticket)}
            >
              {ticket.title}
            </div>
          ))}
          
        </div>

        <div style={{ flex: 1, padding: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h3>Select Engineers</h3>
      <Button variant="primary" onClick={handleAddTicket}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{backgroundColor: isHovered ? '#777373' : '#255a8e', // Change background on hover
        borderColor: isHovered ? '#777373' : '#255a8e', // Change border on hover
        color: 'white', }}>Add Ticket</Button>
    </div>
          <select
            value={selectedEngineer}
            onChange={handleEngineerChange}
            style={{ 
              width: '100%', // Make the select dropdown full width
              marginBottom: '10px', 
              padding: '5px',
              backgroundColor: 'gray', // Set background color to gray
              color: 'white', // Set text color to white
              border: '1px solid #ccc', // Optional: Add border for better appearance
              borderRadius: '4px' // Optional: Add rounded corners
            }}
          >
            {engineers.map(engineer => (
              <option key={engineer.id} value={engineer.id}>
                {engineer.name}
              </option>
            ))}
          </select>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            editable={true}
            droppable={true}
            events={events}
            eventReceive={handleEventReceive}
            eventClick={handleEventClick}
          />
        </div>

        <Modal show={modalIsOpen} onHide={handleModalClose}
        style={{ Width: '100%', margin: 'auto' }} // Adjust width as needed
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent ? 'Ticket Details' : 'Add New Ticket'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedEvent && !selectedTicket && (
              <div>
                <p><strong>Ticket Id:</strong> {selectedEvent?.title}</p>
                <p><strong>Consumer Id:</strong> {selectedEvent?.extendedProps?.consumerId}</p>
                <p><strong>Product Name:</strong> {selectedEvent?.extendedProps?.productName}</p>
                <p><strong>Model Name:</strong> {selectedEvent?.extendedProps?.modelName}</p>
                <p><strong>Ticket Description:</strong> {selectedEvent?.extendedProps?.ticketDescription}</p>
                <p><strong>Ticket Remark:</strong> {selectedEvent?.extendedProps?.ticketRemark}</p>
                <p><strong>Consumer Available Contact Date & Time:</strong> {selectedEvent?.extendedProps?.consumerAvailableContactDateAndTime}</p>
                <p><strong>Address:</strong> {selectedEvent?.extendedProps?.address}</p>
                <p><strong>Country:</strong> {selectedEvent?.extendedProps?.country}</p>
                <p><strong>State:</strong> {selectedEvent?.extendedProps?.state}</p>
                <p><strong>City:</strong> {selectedEvent?.extendedProps?.city}</p>
              </div>
            )}
            {selectedTicket && !selectedEvent && (
              <div>
                <p><strong>Ticket Id:</strong> {selectedTicket?.title}</p>
                <p><strong>Consumer Id:</strong> {selectedTicket?.consumerId}</p>
                <p><strong>Product Name:</strong> {selectedTicket?.productName}</p>
                <p><strong>Model Name:</strong> {selectedTicket?.modelName}</p>
                <p><strong>Ticket Description:</strong> {selectedTicket?.ticketDescription}</p>
                <p><strong>Ticket Remark:</strong> {selectedTicket?.ticketRemark}</p>
                <p><strong>Consumer Available Contact Date & Time:</strong> {selectedTicket?.consumerAvailableContactDateAndTime}</p>
                <p><strong>Address:</strong> {selectedTicket?.address}</p>
                <p><strong>Country:</strong> {selectedTicket?.country}</p>
                <p><strong>State:</strong> {selectedTicket?.state}</p>
                <p><strong>City:</strong> {selectedTicket?.city}</p>
              </div>
            )}
            {!selectedEvent && !selectedTicket && (
              <div className="field">
                <Form>
                <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Consumer Id:</Form.Label>
                        <Form.Control type="text" name="consumerId" value={newTicket.consumerId} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                  
                    <Col>
                      <Form.Group>
                        <Form.Label>Ticket Id:</Form.Label>
                        <Form.Control type="text" name="consumerId" value={newTicket.ticketId} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Consumer First Name:</Form.Label>
                        <Form.Control type="text" name="title" value={newTicket.title} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Consumer Last Name:</Form.Label>
                        <Form.Control type="text" name="lastName" value={newTicket.lastName} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                    </Row>
                    
                  <Row>
                    <Col>
                    <Form.Group>
                <Form.Label>Product Name:</Form.Label>
                <Select
                  isMulti
                  name="productName"
                  options={productOptions}
                  value={newTicket.productName}
                  onChange={handleProductChange}
                />
              </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                <Form.Label>Model Name:</Form.Label>
                <Select
                  isMulti
                  name="modelName"
                  options={modelOptions}
                  value={newTicket.modelName}
                  onChange={handleModelChange}
                />
              </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Ticket Description:</Form.Label>
                        <Form.Control type="text" name="ticketDescription" value={newTicket.ticketDescription} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Ticket Remark:</Form.Label>
                        <Form.Control type="text" name="ticketRemark" value={newTicket.ticketRemark} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Consumer Available Contact Date & Time:</Form.Label>

                        
                        <Form.Control type="text" name="consumerAvailableContactDateAndTime" value={newTicket.consumerAvailableContactDateAndTime} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Address:</Form.Label>
                        <Form.Control type="text" name="address" value={newTicket.address} onChange={handleNewTicketChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
            <Form.Group>
              <Form.Label>Country:</Form.Label>
              <Form.Control as="select" value={selectedCountry} onChange={handleCountryChange}>
                <option value="">Select Country</option>
                {data.countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>State:</Form.Label>
              <Form.Control as="select" value={selectedState} onChange={handleStateChange} disabled={!selectedCountry}>
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
                  </Row>
                  <Row>
                  <Col>
            <Form.Group>
              <Form.Label>City:</Form.Label>
              <Form.Control as="select" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)} disabled={!selectedState}>
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
                  </Row>
                </Form>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose} onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>Close</Button>
            {!selectedEvent && !selectedTicket && (
              <Button variant="primary" onClick={handleAddNewTicketSubmit} 
              style={{backgroundColor: isHovered ? '#255a8e' : '#777373', // Change background on hover
              borderColor: isHovered ? '#255a8e' : '#777373', // Change border on hover
              color: 'white', }} >Add Ticket</Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </Base>
  );
};

export default TicketAssign;
