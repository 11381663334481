import GoogleMapReact from 'google-map-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import 'primereact/resources/primereact.min.css'

const Base = ({children}) => {
    const defaultProps = {
        center: {
          lat: 11.0176,
          lng: 76.9674
        },
        zoom: 12
      };
      const AnyReactComponent = ({ text }) => <div><img src={text} /></div>;
  return (
    <div>
        <Helmet>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <title>ServizPoint Admin</title>
  <link rel="stylesheet" href="../assets/vendors/feather/feather.css" />
  <link rel="stylesheet" href="../assets/vendors/ti-icons/css/themify-icons.css"/>
  <link rel="stylesheet" href="../assets/vendors/css/vendor.bundle.base.css"/>
  
  <link rel="stylesheet" href="../assets/vendors/mdi/css/materialdesignicons.min.css"/>

  <link rel="stylesheet" href="../assets/calendar/css/style.css"/>
  
  <link rel="stylesheet" href="../assets/vendors/datatables.net-bs5/dataTables.bootstrap5.css" />
  <link rel="stylesheet" href="../assets/vendors/ti-icons/css/themify-icons.css" />
  <link rel="stylesheet" type="text/css" href="../assets/js/select.dataTables.min.css"/>
  <link rel="stylesheet" href="../assets/css/style.css"/>
  <link rel="shortcut icon" href="../assets/images/favicon.png" />

  <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.2.0/main.min.css' />
  <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.3.0/main.min.css'/>
</Helmet>


  <div class="container-scroller">

    {/* <!-- partial:partials/_navbar.html --> */}
    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <a class="navbar-brand brand-logo me-5" href="index.html"><img src="../assets/images/logo.png" class="me-2"
            alt="logo" /></a>
        <a class="navbar-brand brand-logo-mini" href="index.html"><img src="../assets/images/logo.png" alt="logo" /></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu"></span>
        </button>
        <ul class="navbar-nav mr-lg-2">
          <li class="nav-item nav-search d-none d-lg-block">
            <div class="input-group">
              <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                <span class="input-group-text" id="search">
                  <i class="icon-search"></i>
                </span>
              </div>
              <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now"
                aria-label="search" aria-describedby="search" />
            </div>
          </li>
        </ul>
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#"
              data-bs-toggle="dropdown">
              <i class="icon-bell mx-0"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown">
              <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-success">
                    <i class="ti-info-alt mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">Application Error</h6>
                  <p class="font-weight-light small-text mb-0 text-muted"> Just now </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-warning">
                    <i class="ti-settings mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">Settings</h6>
                  <p class="font-weight-light small-text mb-0 text-muted"> Private message </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-info">
                    <i class="ti-user mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">New user registration</h6>
                  <p class="font-weight-light small-text mb-0 text-muted"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
              <img src="../assets/images/faces/face28.jpg" alt="profile" />
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a class="dropdown-item">
                <i class="ti-settings text-primary"></i> Settings </a>
              <a class="dropdown-item">
                <i class="ti-power-off text-primary"></i> Logout </a>
            </div>
          </li>
          <li class="nav-item nav-settings d-none d-lg-flex">
            <a class="nav-link" href="#">
              <i class="icon-ellipsis"></i>
            </a>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
          data-toggle="offcanvas">
          <span class="icon-menu"></span>
        </button>
      </div>
    </nav>
    {/* <!-- partial --> */}
    <div class="container-fluid page-body-wrapper">
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          {/* <!-- Sidebar items --> */}
          <li class="nav-item">
          <NavLink className="nav-link" to="/manager/dashboard">
              <i class="icon-grid menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </NavLink>
          </li>
          
          <li class="nav-item">
            <NavLink className="nav-link" to="/manager/ticketAssign">
              <i class="fa fa-ticket menu-icon" aria-hidden="true"></i>
              <span class="menu-title">Ticket Assign</span>
            </NavLink>
          </li>
          <li class="nav-item">
          <NavLink className="nav-link" to="/manager/allTickets">
            
              <i class="fa fa-address-book menu-icon" aria-hidden="true"></i>
              <span class="menu-title">All Tickets</span>
            </NavLink>
          </li>
          {/* <!-- Divider and heading for the master section --> */}
    <div className="nav-divider"></div>
    <h6 className="nav-heading">Master</h6>
          <li class="nav-item">
            <NavLink className="nav-link" to="/manager/consumers">
              <i class="fa fa-users fa-2x menu-icon" aria-hidden="true"></i>
              <span class="menu-title">Consumers</span>
            </NavLink>
          </li>
          <li class="nav-item">
          <NavLink className="nav-link" to="/manager/serviceEngineers">
              <i class="fa fa-address-book menu-icon" aria-hidden="true"></i>
              <span class="menu-title">Service Engineers</span>
            </NavLink>
          </li>
          <li class="nav-item">
          <NavLink className="nav-link" to="/manager/allProducts">
              <i class="fa fa-address-book menu-icon" aria-hidden="true"></i>
              <span class="menu-title">All Products</span>
            </NavLink>
          </li>
          
        </ul>
      </nav>
      {/* <!-- partial --> */}
      <div class="main-panel">
        <div class="content-wrapper">
          {children}

        </div>
        {/* <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html --> */}
        <footer class="footer">
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024. ServizPoint.
              All rights reserved.</span>

          </div>
        </footer>
        {/* <!-- partial --> */}
      </div>
      {/* <!-- main-panel ends --> */}
    </div>
    {/* <!-- page-body-wrapper ends --> */}
  </div>
  {/* <!-- container-scroller -->
  <!-- plugins:js --> */}
  <script src="../assets/vendors/js/vendor.bundle.base.js"></script>
  {/* <!-- endinject -->
  <!-- Plugin js for this page --> */}
  <script src="../assets/vendors/chart.js/chart.umd.js"></script>
  <script src="../assets/vendors/datatables.net/jquery.dataTables.js"></script>
  {/* <!-- <script src="../assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js"></script> --> */}
  <script src="../assets/vendors/datatables.net-bs5/dataTables.bootstrap5.js"></script>
  <script src="../assets/js/dataTables.select.min.js"></script>
  {/* <!-- End plugin js for this page -->
  <!-- inject:js --> */}
  <script src="../assets/js/off-canvas.js"></script>
  <script src="../assets/js/template.js"></script>
  <script src="../assets/js/settings.js"></script>
  <script src="../assets/js/todolist.js"></script>
  {/* <!-- endinject -->
  <!-- Custom js for this page--> */}
  <script src="../assets/js/jquery.cookie.js" type="text/javascript"></script>
  <script src="../assets/js/dashboard.js"></script>
  {/* <!-- <script src="../assets/js/Chart.roundedBarCharts.js"></script> --> */}
  <script src='https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js'></script>
  <script src='https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.2.0/main.min.js'></script>
  <script src='https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.2.0/main.js'></script>
  <script src='https://cdn.jsdelivr.net/npm/@fullcalendar/interaction@4.2.0/main.js'></script>
  <script src='https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js'></script>
  <script src='https://cdn.jsdelivr.net/npm/uuid@8.3.2/dist/umd/uuidv4.min.js'></script>
  <script src="../assets/calendar/js/script.js"></script>
 
    
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGulc2s2DukFlWtxM0jvZf63OHkeVvG6Q&callback=myMap"></script>
</div>
  )
}

export default Base