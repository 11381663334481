export const AllProduct = {
    getAllProductsData() {
        return [
            {
                "id": "1",
                "productid": "208",
                "productname": "Product A",
                "models": [
                    { "modelName": "ModelA123" },
                    { "modelName": "ModelA456" },
                    { "modelName": "ModelA789" }
                ]
            },
            {
                "id": "2",
                "productid": "209",
                "productname": "Product B",
                "models": [
                    { "modelName": "ModelB456" },
                    { "modelName": "ModelB789" },
                    { "modelName": "ModelB012" }
                ]
            },
            {
                "id": "3",
                "productid": "210",
                "productname": "Product C",
                "models": [
                    { "modelName": "ModelC789" },
                    { "modelName": "ModelC012" },
                    { "modelName": "ModelC345" }
                ]
            },
            {
                "id": "4",
                "productid": "211",
                "productname": "Product D",
                "models": [
                    { "modelName": "ModelD321" },
                    { "modelName": "ModelD654" },
                    { "modelName": "ModelD987" }
                ]
            },
            {
                "id": "5",
                "productid": "212",
                "productname": "Product E",
                "models": [
                    { "modelName": "ModelE654" },
                    { "modelName": "ModelE987" },
                    { "modelName": "ModelE123" }
                ]
            },
            {
                "id": "6",
                "productid": "213",
                "productname": "Product F",
                "models": [
                    { "modelName": "ModelF987" },
                    { "modelName": "ModelF123" },
                    { "modelName": "ModelF456" }
                ]
            },
            {
                "id": "7",
                "productid": "214",
                "productname": "Product G",
                "models": [
                    { "modelName": "ModelG147" },
                    { "modelName": "ModelG258" },
                    { "modelName": "ModelG369" }
                ]
            },
            {
                "id": "8",
                "productid": "215",
                "productname": "Product H",
                "models": [
                    { "modelName": "ModelH258" },
                    { "modelName": "ModelH369" },
                    { "modelName": "ModelH147" }
                ]
            },
            {
                "id": "9",
                "productid": "216",
                "productname": "Product I",
                "models": [
                    { "modelName": "ModelI369" },
                    { "modelName": "ModelI147" },
                    { "modelName": "ModelI258" }
                ]
            },
            {
                "id": "10",
                "productid": "217",
                "productname": "Product J",
                "models": [
                    { "modelName": "ModelJ123" },
                    { "modelName": "ModelJ456" },
                    { "modelName": "ModelJ789" }
                ]
            },
            {
                "id": "11",
                "productid": "218",
                "productname": "Product K",
                "models": [
                    { "modelName": "ModelK456" },
                    { "modelName": "ModelK789" },
                    { "modelName": "ModelK012" }
                ]
            },
            {
                "id": "12",
                "productid": "219",
                "productname": "Product L",
                "models": [
                    { "modelName": "ModelL789" },
                    { "modelName": "ModelL012" },
                    { "modelName": "ModelL345" }
                ]
            },
            {
                "id": "13",
                "productid": "220",
                "productname": "Product M",
                "models": [
                    { "modelName": "ModelM321" },
                    { "modelName": "ModelM654" },
                    { "modelName": "ModelM987" }
                ]
            },
            {
                "id": "14",
                "productid": "221",
                "productname": "Product N",
                "models": [
                    { "modelName": "ModelN654" },
                    { "modelName": "ModelN987" },
                    { "modelName": "ModelN123" }
                ]
            },
            {
                "id": "15",
                "productid": "222",
                "productname": "Product O",
                "models": [
                    { "modelName": "ModelO987" },
                    { "modelName": "ModelO123" },
                    { "modelName": "ModelO456" }
                ]
            },
            {
                "id": "16",
                "productid": "223",
                "productname": "Product P",
                "models": [
                    { "modelName": "ModelP147" },
                    { "modelName": "ModelP258" },
                    { "modelName": "ModelP369" }
                ]
            },
            {
                "id": "17",
                "productid": "224",
                "productname": "Product Q",
                "models": [
                    { "modelName": "ModelQ258" },
                    { "modelName": "ModelQ369" },
                    { "modelName": "ModelQ147" }
                ]
            },
            {
                "id": "18",
                "productid": "225",
                "productname": "Product R",
                "models": [
                    { "modelName": "ModelR369" },
                    { "modelName": "ModelR147" },
                    { "modelName": "ModelR258" }
                ]
            },
            {
                "id": "19",
                "productid": "226",
                "productname": "Product S",
                "models": [
                    { "modelName": "ModelS123" },
                    { "modelName": "ModelS456" },
                    { "modelName": "ModelS789" }
                ]
            },
            {
                "id": "20",
                "productid": "227",
                "productname": "Product T",
                "models": [
                    { "modelName": "ModelT456" },
                    { "modelName": "ModelT789" },
                    { "modelName": "ModelT123" }
                ]
            },
            {
                "id": "21",
                "productid": "228",
                "productname": "Product U",
                "models": [
                    { "modelName": "ModelU789" },
                    { "modelName": "ModelU123" },
                    { "modelName": "ModelU456" }
                ]
            },
            {
                "id": "22",
                "productid": "229",
                "productname": "Product V",
                "models": [
                    { "modelName": "ModelV321" },
                    { "modelName": "ModelV654" },
                    { "modelName": "ModelV987" }
                ]
            },
            {
                "id": "23",
                "productid": "230",
                "productname": "Product W",
                "models": [
                    { "modelName": "ModelW654" },
                    { "modelName": "ModelW987" },
                    { "modelName": "ModelW123" }
                ]
            },
            {
                "id": "24",
                "productid": "231",
                "productname": "Product X",
                "models": [
                    { "modelName": "ModelX987" },
                    { "modelName": "ModelX123" },
                    { "modelName": "ModelX456" }
                ]
            },
            {
                "id": "25",
                "productid": "232",
                "productname": "Product Y",
                "models": [
                    { "modelName": "ModelY147" },
                    { "modelName": "ModelY258" },
                    { "modelName": "ModelY369" }
                ]
            },
            {
                "id": "26",
                "productid": "233",
                "productname": "Product Z",
                "models": [
                    { "modelName": "ModelZ258" },
                    { "modelName": "ModelZ369" },
                    { "modelName": "ModelZ147" }
                ]
            },
            {
                "id": "27",
                "productid": "234",
                "productname": "Product AA",
                "models": [
                    { "modelName": "ModelAA369" },
                    { "modelName": "ModelAA147" },
                    { "modelName": "ModelAA258" }
                ]
            },
            {
                "id": "28",
                "productid": "235",
                "productname": "Product BB",
                "models": [
                    { "modelName": "ModelBB123" },
                    { "modelName": "ModelBB456" },
                    { "modelName": "ModelBB789" }
                ]
            },
            {
                "id": "29",
                "productid": "236",
                "productname": "Product CC",
                "models": [
                    { "modelName": "ModelCC456" },
                    { "modelName": "ModelCC789" },
                    { "modelName": "ModelCC123" }
                ]
            },
            {
                "id": "30",
                "productid": "237",
                "productname": "Product DD",
                "models": [
                    { "modelName": "ModelDD789" },
                    { "modelName": "ModelDD123" },
                    { "modelName": "ModelDD456" }
                ]
            }
        ]
        
        
    },

    getAllProductsMini() {
        return Promise.resolve(this.getAllProductsData().slice(0, 5));
    },

    getAllProductsSmall() {
        return Promise.resolve(this.getAllProductsData().slice(0, 10));
    },

    getAllProducts() {
        return Promise.resolve(this.getAllProductsData());
    },

    getAllProductsWithOrdersSmall() {
        return Promise.resolve(this.getAllProductsWithOrdersData().slice(0, 10));
    },

    getAllProductsWithOrders() {
        return Promise.resolve(this.getAllProductsWithOrdersData());
    }
};

