import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";


const Home = () => {
  return (
    <div>
      <div className="container p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <img className="m-3" src="/assets/images/logo.png" />
            <h1 className="text-center m-3 ">Home Page</h1>
            <h4 className="text-center text-primary m-3">
              Welcome to the ServizPoint website!
            </h4>

            <div className="row">
              <div className="col-md-6">
                <NavLink to="/manager/login">
                  <button className="btn btn-warning btn-lg w-100">Manager</button>
                </NavLink>
              </div>
              <div className="col-md-6">
                <NavLink to="/consumer/login">
                  <button className="btn btn-warning btn-lg w-100">Consumer</button>
                </NavLink> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
