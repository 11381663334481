import Base from "./Base";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import{ AllProduct} from "./service/AllProduct";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea"; // Corrected import
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import "./styles.css"; //
import { Password } from 'primereact/password';
import { MultiSelect } from 'primereact/multiselect';
import { Chips } from "primereact/chips";

const AllProducts = () => {
 
  const [allProduct, setAllProduct] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allProductDialog, setAllProductDialog] = useState(false);
  const [deleteAllProductDialog, setDeleteAllProductDialog] = useState(false);
  const [deleteAllProductsDialog, setDeleteAllProductsDialog] = useState(false);
  const [reorderColumnsDialog, setReorderColumnsDialog] = useState(false);
 
  const [submitted, setSubmitted] = useState(false);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  
  const [globalFilter, setGlobalFilter] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState("");

const [selectedProducts, setSelectedProducts] = useState([]);
const [selectedAllProducts, setSelectedAllProducts] = useState([]);
const [availableModels, setAvailableModels] = useState([]);

const [selectedModels, setSelectedModels] = useState([]);


const Category = [
    { name: 'Category 1', code: 'C1' },
    { name: 'Category 2', code: 'C2' },
    { name: 'Category 3', code: 'C3' },
    { name: 'Category 4', code: 'C4' },
    { name: 'Category 5', code: 'C5' }
    
];

const Area = [
    { name: 'Area 1', code: 'A1' },
    { name: 'Area 2', code: 'A2' },
    { name: 'Area 3', code: 'A3' },
    { name: 'Area 4', code: 'A4' },
    { name: 'Area 5', code: 'A5' }
    
];

const [product, setProduct] = useState({
  id: '',
  productid: '',
  productname: '',
  models: []
});


const emptyProduct = {
  id: '',
  productid: '',
  productname: '',
  models: []
};

const onInputChange = (e, fieldName, index = null) => {
  const { value } = e.target;

  setProduct(prevProduct => {
    if (fieldName === 'productname') {
      return {
        ...prevProduct,
        productname: value,
      };
    }

    if (fieldName === 'productid') {
      return {
        ...prevProduct,
        productid: value,
      };
    }

    if (fieldName === 'id') {
      return {
        ...prevProduct,
        id: value,
      };
    }

    if (fieldName === 'modelName' && index !== null) {
      const updatedModels = prevProduct.models.map((model, i) =>
        i === index ? { ...model, modelName: value } : model
      );

      return {
        ...prevProduct,
        models: updatedModels,
      };
    }

    // Return previous state if fieldName is not recognized
    return prevProduct;
  });
};



  const addModel = () => {
    setProduct(prevProduct => ({
      ...prevProduct,
      models: [...prevProduct.models, { modelName: '' }],
    }));
  };
  
  const removeModel = (index) => {
    setProduct(prevProduct => ({
      ...prevProduct,
      models: prevProduct.models.filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const models = selectedProducts.flatMap(productName => {
        const product = productdata.products.find(p => p.name === productName);
        return product ? product.models : [];
      });
      console.log("Available Models:", models); // Debug log
      setAvailableModels(models);
    } else {
      setAvailableModels([]);
    }
  }, [selectedProducts]);
  
  useEffect(() => {
    // Load models when selectedProduct changes
    if (selectedProduct) {
      const product = productdata.products.find(p => p.name === selectedProduct);
      if (product) {
        setProduct({
          productName: product.name,
          models: product.models.map(model => ({ modelName: model.name })),
        });
      }
    }
  }, [selectedProduct]);


  

  const [availableColumns, setAvailableColumns] = useState([
    
    { label: "Product ID", id: "productid" },
    { label: "Product Name", id: "productname" },
    { label: "Models", id: "models" } // Ensure this entry is present
    
  ]);



  const [selectedColumns, setSelectedColumns] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    AllProduct.getAllProducts().then((data) => setAllProducts(data));
    setSelectedColumns(availableColumns);
    setSource(availableColumns);
  }, []);

  
  const handleColumnChange = (e) => {
    setSource(e.source);
    setTarget(e.target);
  };

  const saveAllProduct = () => {
    setSubmitted(true);
    console.log("Product State Before Save:", product);
    // Clone existing products and the current product
    let _allProducts = [...allProducts];
    let _product = { ...product };
    if (_product.id) {
      // Find the index of the product to update
      const index = _allProducts.findIndex((t) => t.id === _product.id);
      if (index !== -1) {
        // Update the existing product
        _allProducts[index] = _product;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Updated",
          life: 3000,
        });
      }
    } else {
      // Assign a new unique ID for the new product
      _product.id = new Date().getTime(); // Unique ID generator
      _allProducts.push(_product);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Product Created",
        life: 3000,
      });
    }
  
    console.log("All Products After Save:", _allProducts);
  
    // Update the state with the modified products list
    setAllProducts(_allProducts);
    setAllProductDialog(false);
    setAllProduct(emptyProduct);
    setSelectedProducts(emptyProduct);
    setSelectedAllProducts(emptyProduct)
    
    
  };

  const hideDialog = () => {
    setAllProduct(emptyProduct);
    setAllProductDialog(false);
    setSelectedProducts("");
    setSelectedAllProducts("")
  };
  
  
  

  const handleProductChange = (e) => {
    setSelectedProducts(e.value);
  };
  
  const handleModelChange = (e) => {
    setSelectedModels(e.value);
  };

   const imageBodyTemplate = (rowData) => {
        return (
            <img
                src={`/assets/images/${rowData.productImages}`}
                alt={rowData.productImages}
                className="shadow-2 border-round"
                style={{ width: '64px' }}
            />
        );
    };
  const deleteAllProduct = () => {
    let _allProducts = allProducts.filter((val) => val.id !== allProduct.id);
    setAllProducts(_allProducts);
    setDeleteAllProductDialog(false);
    
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const deleteSelectedAllProducts = () => {
    let _allProducts = allProducts.filter((val) => !selectedAllProducts.includes(val));
    setAllProducts(_allProducts);
    setDeleteAllProductsDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const onSelectionChange = (e) => {
    setSelectedAllProducts(e.value);
  };

  const editAllProduct = (rowData) => {
    setProduct({ ...rowData });
    setAllProductDialog(true);
  };
 

  const productdata = {
    products: [
      {
        name: "Product A",
        models: [
          { name: "ModelA123" },
          { name: "ModelB234" },
        ],
      },
      {
        name: "Product B",
        models: [
          { name: "ModelC345" },
          { name: "ModelD456" },
        ],
      },
    ],
  };

  const rowActionsBodyTemplate = (rowData) => {
    
    return (
      <div className="actions">
       
          <Button
            icon="pi pi-pencil"
            className="custom-button-edit p-button-rounded p-button-sm"
            onClick={() => editAllProduct(rowData)}
          />
        
       
          <Button
            icon="pi pi-trash"
            className="custom-button-delete p-button-rounded p-button-sm"
            onClick={() => {
              setAllProduct(rowData);
              setDeleteAllProductDialog(true);
            }}
          />
        
      </div>
    );
  };

  const openReorder = () => {
    setReorderColumnsDialog(true);
  };

  const newAllProduct = () => {
    setAllProduct(emptyProduct);
    setAllProductDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          className="button-new"
          onClick={newAllProduct}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="button-delete"
          onClick={() => setDeleteAllProductsDialog(true)}
          disabled={!selectedAllProducts.length} // Disable if no tickets selected
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="button-export mx-2"
          onClick={() => dt.current.exportCSV()}
        />
        <Button
          label="Reorder Columns"
          icon="pi pi-sort"
          className="button-reorder"
          onClick={openReorder}
        />
      </div>
    );
  };

  const header = (
    <div className="flex items-center justify-between gap-2">
  <h4 className="m-0">All Products</h4>
  <div className="flex items-center p-input-icon p-input-icon-left">
    
    <InputText
      type="search"
      onInput={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search..."
      className="ml-2"
    />
  </div>
</div>
  );

  const allProductDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => hideDialog(false)}
        className="cancel-button" // Apply CSS class for Cancel button
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={saveAllProduct}
        className="edit-button" // Apply CSS class for Edit button
      />
    </React.Fragment>
  );

  const deleteAllProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteAllProductDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteAllProduct} />
    </React.Fragment>
  );

  const deleteAllProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteAllProductsDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteSelectedAllProducts} />
    </React.Fragment>
  );
  // ===================================SAVE REORDER COMPONENTS==========================================
  const saveReorderColumn = () => {
    setSelectedColumns(target);
    setReorderColumnsDialog(false);
  };

  const handleChipsChange = (e) => {
    // Map the chip values to the models array
    const updatedModels = e.value.map(modelName => ({ modelName }));
    setProduct(prevProduct => ({
      ...prevProduct,
      models: updatedModels,
    }));
  };

  const reorderColumnsDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => hideDialog(false)}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveReorderColumn} />
    </React.Fragment>
  );
  // ===================================END OF SAVE REORDER COMPONENTS==========================================
  

  // ===================================VIEW COMPONENTS==========================================
  return (
    <Base>
      <div>
        <Toast ref={toast} />

        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />

        {selectedColumns && selectedColumns.length > 0 ? (
         <DataTable
         ref={dt}
         value={allProducts}
         selection={selectedAllProducts}
         onSelectionChange={onSelectionChange}
         dataKey="id"
         paginator
         rows={10}
         rowsPerPageOptions={[10, 20, 30]}
         header={header}
         globalFilter={globalFilter}
         emptyMessage="No AllProduct found."
       >
         <Column selectionMode="multiple" exportable={false} />
         
         {selectedColumns.map((col) => (
           <Column
             key={col.id}
             field={col.id}
             header={col.label}
             sortable
             body={(rowData) => {
               if (col.id === 'models') {
                 return (
                   <div>
                     {rowData.models.map((model, index) => (
                       <div key={index}>{model.modelName}</div>
                     ))}
                   </div>
                 );
               }
               return rowData[col.id];
             }}
           />
         ))}
       
         <Column
           body={rowActionsBodyTemplate}
           exportable={false}
           className="mx-2"
           style={{ minWidth: "12rem" }}
         />
       </DataTable>
       
        
        ) : (
          <div className="card">
            <h4 style={{ textAlign: "center", padding: "1rem" }}>
              No Columns have been selected{" "}
            </h4>
          </div>
        )}

        <Dialog
          visible={allProductDialog}
          style={{ width: "700px" }}
          header={allProduct.id ? "Edit Products Details" : "Create Products Details"}
          modal
          className="p-fluid dialog custom-dialog-header"
          footer={allProductDialogFooter}
          onHide={() => setAllProductDialog(false)}
        > 
          <div className="form-grid">
          
          <div className="field">
      <label htmlFor="productId" className="bold-label">
        Product Id
      </label>
      <InputText
        id="productName"
        value={product.productid}
        onChange={(e) => onInputChange(e, "productid")}
      />
    </div>
    <div className="field">
      <label htmlFor="productName" className="bold-label">
        Product Name
      </label>
      <InputText
        id="productName"
        value={product.productname}
        onChange={(e) => onInputChange(e, "productname")}
      />
    </div>
   <div className="field">
      <label htmlFor="productModel" className="bold-label">
        Model Names
      </label>
     
      <Chips
        value={product.models.map(model => model.modelName)} // Map model names to the chips value
        onChange={handleChipsChange} // Update state when value changes
      />
    </div>
            </div>
          
        </Dialog>

        <Dialog
          visible={deleteAllProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteAllProductDialogFooter}
          onHide={() => setDeleteAllProductDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {allProduct && (
              <span>
                Are you sure you want to delete allProducts{" "}
                <b>{allProduct.allProductRaiseUserId}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteAllProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteAllProductsDialogFooter}
          onHide={() => setDeleteAllProductsDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to delete the selected All Products?</span>
          </div>
        </Dialog>

        <Dialog
          visible={reorderColumnsDialog}
          style={{ width: "700px" }}
          header="Reorder Columns"
          modal
          footer={reorderColumnsDialogFooter}
          onHide={() => setReorderColumnsDialog(false)}
        >
          <PickList
            source={source}
            target={target}
            onChange={handleColumnChange}
            itemTemplate={(item) => item.label}
            sourceHeader="Available Columns"
            targetHeader="Selected Columns"
          />
        </Dialog>
      </div>
    </Base>
  );
};

export default AllProducts;
