export const Product = {
    getProductsData() {
        return [
            {
                "id": 1,
                "productName": "Ac",
                "modelName": "XYZ789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-07T09:00:00",
                "updateDateAndTime": "2024-08-07T09:00:00"
            },
            {
                "id": 2,
                "productName": "Fridge",
                "modelName": "FRG456",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-08T10:30:00",
                "updateDateAndTime": "2024-08-08T10:30:00"
            },
            {
                "id": 3,
                "productName": "Washing Machine",
                "modelName": "WMN321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-09T11:45:00",
                "updateDateAndTime": "2024-08-09T11:45:00"
            },
            {
                "id": 4,
                "productName": "Oven",
                "modelName": "OVN654",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-10T12:00:00",
                "updateDateAndTime": "2024-08-10T12:00:00"
            },
            {
                "id": 5,
                "productName": "Dishwasher",
                "modelName": "DWS789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-11T13:15:00",
                "updateDateAndTime": "2024-08-11T13:15:00"
            },
            {
                "id": 6,
                "productName": "Microwave",
                "modelName": "MWC123",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-12T14:30:00",
                "updateDateAndTime": "2024-08-12T14:30:00"
            },
            {
                "id": 7,
                "productName": "Toaster",
                "modelName": "TST456",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-13T15:45:00",
                "updateDateAndTime": "2024-08-13T15:45:00"
            },
            {
                "id": 8,
                "productName": "Blender",
                "modelName": "BLD789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-14T16:00:00",
                "updateDateAndTime": "2024-08-14T16:00:00"
            },
            {
                "id": 9,
                "productName": "Coffee Maker",
                "modelName": "CMK321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-15T17:15:00",
                "updateDateAndTime": "2024-08-15T17:15:00"
            },
            {
                "id": 10,
                "productName": "Vacuum Cleaner",
                "modelName": "VCL654",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-16T18:30:00",
                "updateDateAndTime": "2024-08-16T18:30:00"
            },
            {
                "id": 11,
                "productName": "Air Purifier",
                "modelName": "APU987",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-17T19:45:00",
                "updateDateAndTime": "2024-08-17T19:45:00"
            },
            {
                "id": 12,
                "productName": "Heater",
                "modelName": "HTR234",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-18T20:00:00",
                "updateDateAndTime": "2024-08-18T20:00:00"
            },
            {
                "id": 13,
                "productName": "Fan",
                "modelName": "FAN567",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-19T21:15:00",
                "updateDateAndTime": "2024-08-19T21:15:00"
            },
            {
                "id": 14,
                "productName": "Dehumidifier",
                "modelName": "DHM890",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-20T22:30:00",
                "updateDateAndTime": "2024-08-20T22:30:00"
            },
            {
                "id": 15,
                "productName": "Rice Cooker",
                "modelName": "RCO123",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-21T23:45:00",
                "updateDateAndTime": "2024-08-21T23:45:00"
            },
            {
                "id": 16,
                "productName": "Grill",
                "modelName": "GRL456",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-22T00:00:00",
                "updateDateAndTime": "2024-08-22T00:00:00"
            },
            {
                "id": 17,
                "productName": "Cooktop",
                "modelName": "CPT789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-23T01:15:00",
                "updateDateAndTime": "2024-08-23T01:15:00"
            },
            {
                "id": 18,
                "productName": "Dish Rack",
                "modelName": "DRK321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-24T02:30:00",
                "updateDateAndTime": "2024-08-24T02:30:00"
            },
            {
                "id": 19,
                "productName": "Food Processor",
                "modelName": "FPR654",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-25T03:45:00",
                "updateDateAndTime": "2024-08-25T03:45:00"
            },
            {
                "id": 20,
                "productName": "Juicer",
                "modelName": "JCR987",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-26T04:00:00",
                "updateDateAndTime": "2024-08-26T04:00:00"
            },
            {
                "id": 21,
                "productName": "Electric Kettle",
                "modelName": "EKL321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-27T05:15:00",
                "updateDateAndTime": "2024-08-27T05:15:00"
            },
            {
                "id": 22,
                "productName": "Slow Cooker",
                "modelName": "SLC654",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-28T06:30:00",
                "updateDateAndTime": "2024-08-28T06:30:00"
            },
            {
                "id": 23,
                "productName": "Popcorn Maker",
                "modelName": "PMK789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-29T07:45:00",
                "updateDateAndTime": "2024-08-29T07:45:00"
            },
            {
                "id": 24,
                "productName": "Sandwich Maker",
                "modelName": "SMK321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-30T08:00:00",
                "updateDateAndTime": "2024-08-30T08:00:00"
            },
            {
                "id": 25,
                "productName": "Electric Grill",
                "modelName": "EGL456",
                "productImages": "logo.png",
                "createDateAndTime": "2024-08-31T09:15:00",
                "updateDateAndTime": "2024-08-31T09:15:00"
            },
            {
                "id": 26,
                "productName": "Ice Cream Maker",
                "modelName": "ICM789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-09-01T10:30:00",
                "updateDateAndTime": "2024-09-01T10:30:00"
            },
            {
                "id": 27,
                "productName": "Wine Cooler",
                "modelName": "WCL123",
                "productImages": "logo.png",
                "createDateAndTime": "2024-09-02T11:45:00",
                "updateDateAndTime": "2024-09-02T11:45:00"
            },
            {
                "id": 28,
                "productName": "Air Conditioner",
                "modelName": "ACC456",
                "productImages": "logo.png",
                "createDateAndTime": "2024-09-03T12:00:00",
                "updateDateAndTime": "2024-09-03T12:00:00"
            },
            {
                "id": 29,
                "productName": "Humidifier",
                "modelName": "HUM789",
                "productImages": "logo.png",
                "createDateAndTime": "2024-09-04T13:15:00",
                "updateDateAndTime": "2024-09-04T13:15:00"
            },
            {
                "id": 30,
                "productName": "Electric Oven",
                "modelName": "EOV321",
                "productImages": "logo.png",
                "createDateAndTime": "2024-09-05T14:30:00",
                "updateDateAndTime": "2024-09-05T14:30:00"
            }

           
        ]        
    },

    getProductsMini() {
        return Promise.resolve(this.getProductsData().slice(0, 5));
    },

    getProductsSmall() {
        return Promise.resolve(this.getProductsData().slice(0, 10));
    },

    getProducts() {
        return Promise.resolve(this.getProductsData());
    },

    getProductsWithOrdersSmall() {
        return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    },

    getProductsWithOrders() {
        return Promise.resolve(this.getProductsWithOrdersData());
    }
};