export const Ticket = {
    getTicketsData() {
        return [
            {
                "id": 1,
                "ticketRaiseUserId": 1,
                "consumerId": 101,
                "productId": 202,
                "modelId": 303,
                "ticketRemark": "The product is not turning on despite multiple attempts.",
                "productName": "Ac",
                "modelName": "ABC123",
                "ticketDescription": "Noise problem",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "",
                "assignedSE": "",
                "serviceAssignedDateAndTime": "",
                "address": "123 Maple Street, New York, NY",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 2,
                "ticketRaiseUserId": 2,
                "consumerId": 102,
                "productId": 203,
                "modelId": 304,
                "ticketRemark": "The device is making a strange noise.",
                "productName": "Washing Machine",
                "modelName": "XYZ789",
                "ticketDescription": "Leakage issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Bob Smith",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "456 Oak Avenue, Los Angeles, CA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Inprogress",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Chennai",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 3,
                "ticketRaiseUserId": 3,
                "consumerId": 103,
                "productId": 204,
                "modelId": 305,
                "ticketRemark": "The refrigerator is not cooling properly.",
                "productName": "Refrigerator",
                "modelName": "LMN456",
                "ticketDescription": "Cooling issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Charlie Brown",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "789 Pine Road, Chicago, IL",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": "08/12/2024 16:23",
                "ticketStatus": "Completed",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 4,
                "ticketRaiseUserId": 4,
                "consumerId": 104,
                "productId": 205,
                "modelId": 306,
                "ticketRemark": "The air conditioner is leaking water.",
                "productName": "AC",
                "modelName": "OPQ012",
                "ticketDescription": "Leakage issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "David Wilson",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "101 Elm Street, Houston, TX",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 5,
                "ticketRaiseUserId": 5,
                "consumerId": 105,
                "productId": 206,
                "modelId": 307,
                "ticketRemark": "The washing machine is not spinning.",
                "productName": "Washing Machine",
                "modelName": "RST345",
                "ticketDescription": "Spinning issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Eva Green",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "202 Birch Lane, Philadelphia, PA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 6,
                "ticketRaiseUserId": 6,
                "consumerId": 106,
                "productId": 207,
                "modelId": 308,
                "ticketRemark": "The refrigerator door is not sealing properly.",
                "productName": "Refrigerator",
                "modelName": "UVW678",
                "ticketDescription": "Door sealing issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Frank Harris",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "303 Cedar Street, San Francisco, CA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Madurai",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 7,
                "ticketRaiseUserId": 7,
                "consumerId": 107,
                "productId": 208,
                "modelId": 309,
                "ticketRemark": "The air conditioner remote is not working.",
                "productName": "AC",
                "modelName": "XYZ123",
                "ticketDescription": "Remote control issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Grace Lee",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "404 Willow Street, Seattle, WA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Madurai",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 8,
                "ticketRaiseUserId": 8,
                "consumerId": 108,
                "productId": 209,
                "modelId": 310,
                "ticketRemark": "The washing machine is making loud noises.",
                "productName": "Washing Machine",
                "modelName": "DEF456",
                "ticketDescription": "Noise issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Hannah Adams",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "505 Spruce Drive, Boston, MA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Madurai",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 9,
                "ticketRaiseUserId": 9,
                "consumerId": 109,
                "productId": 210,
                "modelId": 311,
                "ticketRemark": "The refrigerator is emitting a strange odor.",
                "productName": "Refrigerator",
                "modelName": "GHI789",
                "ticketDescription": "Odor issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Ian Turner",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "606 Pine Street, Denver, CO",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 10,
                "ticketRaiseUserId": 10,
                "consumerId": 110,
                "productId": 211,
                "modelId": 312,
                "ticketRemark": "The air conditioner is not cooling.",
                "productName": "AC",
                "modelName": "JKL890",
                "ticketDescription": "Cooling issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Jack Robinson",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "707 Cedar Street, Austin, TX",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Chennai",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 11,
                "ticketRaiseUserId": 11,
                "consumerId": 111,
                "productId": 212,
                "modelId": 313,
                "ticketRemark": "The washing machine is leaking water.",
                "productName": "Washing Machine",
                "modelName": "MNO123",
                "ticketDescription": "Leakage issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Laura Scott",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "808 Birch Street, San Diego, CA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Bengaluru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 12,
                "ticketRaiseUserId": 12,
                "consumerId": 112,
                "productId": 213,
                "modelId": 314,
                "ticketRemark": "The refrigerator is not defrosting.",
                "productName": "Refrigerator",
                "modelName": "PQR456",
                "ticketDescription": "Defrosting issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Michael Walker",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "909 Maple Avenue, Seattle, WA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Bengaluru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 13,
                "ticketRaiseUserId": 13,
                "consumerId": 113,
                "productId": 214,
                "modelId": 315,
                "ticketRemark": "The AC is not turning off.",
                "productName": "AC",
                "modelName": "STU567",
                "ticketDescription": "Power issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Nancy Allen",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1010 Oak Lane, Miami, FL",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Bengaluru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 14,
                "ticketRaiseUserId": 14,
                "consumerId": 114,
                "productId": 215,
                "modelId": 316,
                "ticketRemark": "The washing machine is not draining.",
                "productName": "Washing Machine",
                "modelName": "VWX678",
                "ticketDescription": "Drainage issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Olivia Wright",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1212 Pine Street, Atlanta, GA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Bengaluru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 15,
                "ticketRaiseUserId": 15,
                "consumerId": 115,
                "productId": 216,
                "modelId": 317,
                "ticketRemark": "The refrigerator is freezing food in the refrigerator compartment.",
                "productName": "Refrigerator",
                "modelName": "YZA789",
                "ticketDescription": "Temperature issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Paul Green",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1313 Maple Street, Portland, OR",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Bengaluru",
                "selectedCity": "Srinagar",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 16,
                "ticketRaiseUserId": 16,
                "consumerId": 116,
                "productId": 217,
                "modelId": 318,
                "ticketRemark": "The AC is blowing warm air.",
                "productName": "AC",
                "modelName": "BCD890",
                "ticketDescription": "Airflow issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Emily Harris",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1414 Birch Avenue, Dallas, TX",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Mysuru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 17,
                "ticketRaiseUserId": 17,
                "consumerId": 117,
                "productId": 218,
                "modelId": 319,
                "ticketRemark": "The washing machine is not starting.",
                "productName": "Washing Machine",
                "modelName": "EFG123",
                "ticketDescription": "Startup issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Michael Roberts",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1515 Cedar Lane, San Jose, CA",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Mysuru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 18,
                "ticketRaiseUserId": 18,
                "consumerId": 118,
                "productId": 219,
                "modelId": 320,
                "ticketRemark": "The refrigerator is not making ice.",
                "productName": "Refrigerator",
                "modelName": "HIJ456",
                "ticketDescription": "Ice maker issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Sarah Miller",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1616 Oak Street, Minneapolis, MN",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Mysuru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 19,
                "ticketRaiseUserId": 19,
                "consumerId": 119,
                "productId": 220,
                "modelId": 321,
                "ticketRemark": "The AC is not responding to the thermostat.",
                "productName": "AC",
                "modelName": "KLM789",
                "ticketDescription": "Thermostat issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Ryan Carter",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1717 Pine Avenue, Baltimore, MD",
                "productImages": "logo.png",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Mysuru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 20,
                "ticketRaiseUserId": 20,
                "consumerId": 120,
                "productId": 221,
                "modelId": 322,
                "ticketRemark": "The washing machine door is not closing properly.",
                "productName": "Washing Machine",
                "modelName": "NOP012",
                "ticketDescription": "Door issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Anna Morris",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1818 Maple Avenue, Detroit, MI",
                "productImages": "product20-image.jpg",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "India",
                "selectedState": "Karnataka",
                "selectedCity": "Mysuru",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 21,
                "ticketRaiseUserId": 21,
                "consumerId": 121,
                "productId": 222,
                "modelId": 323,
                "ticketRemark": "The refrigerator is making a loud noise.",
                "productName": "Refrigerator",
                "modelName": "QRS345",
                "ticketDescription": "Noise issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Oliver Allen",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "1919 Oak Lane, Austin, TX",
                "productImages": "product21-image.jpg",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "USA",
                "selectedState": "New York",
                "selectedCity": "New York City",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 22,
                "ticketRaiseUserId": 22,
                "consumerId": 122,
                "productId": 223,
                "modelId": 324,
                "ticketRemark": "The AC is leaking refrigerant.",
                "productName": "AC",
                "modelName": "TUV678",
                "ticketDescription": "Refrigerant issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "Sophia White",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "2020 Willow Street, Denver, CO",
                "productImages": "product22-image.jpg",
                "serviceCompletedDateAndTime": null,
                "ticketStatus": "Open",
                "selectedCountry": "USA",
                "selectedState": "New York",
                "selectedCity": "Buffalo",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 23,
                "ticketRaiseUserId": 23,
                "consumerId": 123,
                "productId": 224,
                "modelId": 325,
                "ticketRemark": "The washing machine drum is not spinning.",
                "productName": "Washing Machine",
                "modelName": "UVW901",
                "ticketDescription": "Drum issue",
                "consumerAvailableContactDateAndTime": "08/12/2024 16:23",
                "serviceExpectedCompletionDateAndTime": "08/12/2024 16:23",
                "assignedSE": "James Lee",
                "serviceAssignedDateAndTime": "08/12/2024 16:23",
                "address": "2323 Birch Street, Philadelphia, PA",
                "productImages": "product23-image.jpg",
                "serviceCompletedDateAndTime": "08/12/2024 16:23",
                "ticketStatus": "Open",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "San Francisco",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            }
        ]        
    },

    getTicketsMini() {
        return Promise.resolve(this.getTicketsData().slice(0, 5));
    },

    getTicketsSmall() {
        return Promise.resolve(this.getTicketsData().slice(0, 10));
    },

    getTickets() {
        return Promise.resolve(this.getTicketsData());
    },

    getTicketsWithOrdersSmall() {
        return Promise.resolve(this.getTicketsWithOrdersData().slice(0, 10));
    },

    getTicketsWithOrders() {
        return Promise.resolve(this.getTicketsWithOrdersData());
    }
};

