import GoogleMapReact from 'google-map-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import Base from './Base'

const Dashboard = () => {
    const defaultProps = {
        center: {
          lat: 11.0176,
          lng: 76.9674
        },
        zoom: 12
      };
      const AnyReactComponent = ({ text }) => <div><img src={text} /></div>;

  return (
    <Base>
       
          {/* <!-- GOOGLE MAP WITH STATS --> */}
          <div class="row grid-margin">
            <div class="col-md-6 stretch-card grid-margin grid-margin-md-0">
              <div class="card companyColor">
                <div class="card-body">
                  <div class="d-flex mb-1 flex-row justify-content-between align-items-center">
                    <p class="card-title text-white">Tickets and Engineers</p>
                    <div>
                      <button class="btn btn-sm btn-light">
                        <img src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" class="markerIcon" />
                        <img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" class="markerIcon"/>
                      </button>
                      <button class="btn btn-sm btn-light">
                        <img src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" class="markerIcon"/>
                        Tickets</button>
                      <button class="btn btn-sm btn-light">
                        <img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" class="markerIcon"/>
                        Engineers</button>
                    </div>
                  </div>
                  <div class="row">
                  <div style={{ height: '450px', width: '100%' }}>
                  <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAGulc2s2DukFlWtxM0jvZf63OHkeVvG6Q" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
       <AnyReactComponent
          lat={11.0576}
          lng={76.9774}
          text="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
        />
      </GoogleMapReact>
      </div>
                   
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 transparent">
              <div class="row">
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card bg-danger text-white">
                    <div class="card-body">
                      <p class="mb-4">Today’s Open Tickets</p>
                      <p class="fs-30 mb-2">4006</p>
                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card card-danger text-white">
                    <div class="card-body">
                      <p class="mb-4">Total Open Tickets</p>
                      <p class="fs-30 mb-2">61344</p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card bg-warning text-white">
                    <div class="card-body">
                      <p class="mb-4">Today’s In-Progress Tickets</p>
                      <p class="fs-30 mb-2">4006</p>
                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card card-warning text-white">
                    <div class="card-body">
                      <p class="mb-4">Total In-Progress Tickets</p>
                      <p class="fs-30 mb-2">61344</p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card bg-success text-white">
                    <div class="card-body">
                      <p class="mb-4">Today's Closed Tickets</p>
                      <p class="fs-30 mb-2">4006</p>
                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-4 stretch-card transparent">
                  <div class="card card-success text-white">
                    <div class="card-body">
                      <p class="mb-4">Total Closed Tickets</p>
                      <p class="fs-30 mb-2">61344</p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                  <div class="card bg-secondary text-white">
                    <div class="card-body">
                      <p class="mb-4">Total Consumers</p>
                      <p class="fs-30 mb-2">34040</p>
                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6 stretch-card transparent">
                  <div class="card bg-secondary text-white">
                    <div class="card-body">
                      <p class="mb-4">Total Service Engineers</p>
                      <p class="fs-30 mb-2">47033</p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
{/* <!-- END OF GOOGLE MAP WITH STATS -->
          <!-- CALENDAR ROW WITH CHECKLIST AND ENGINEERS --> */}
          <div class="row">
             
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Today's Tickets</h4>
                    <div class="list-wrapper pt-2">
                      <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
                        <li>
                          <div class="form-check form-check-flat">
                            <label class="form-check-label">
                              <input class="checkbox" type="checkbox" /> Ticket-0001 </label>
                          </div>
                          
                        </li>
                        <li class="completed">
                          <div class="form-check form-check-flat">
                            <label class="form-check-label">
                              <input class="checkbox" type="checkbox" checked /> Ticket-0006 </label>
                          </div>
                         
                        </li>
                        <li>
                          <div class="form-check form-check-flat">
                            <label class="form-check-label">
                              <input class="checkbox" type="checkbox" /> Ticket-0007 </label>
                          </div>
                         
                        </li>
                        <li class="completed">
                          <div class="form-check form-check-flat">
                            <label class="form-check-label">
                              <input class="checkbox" type="checkbox" checked /> Ticket-0003 </label>
                          </div>
                          
                        </li>
                        <li>
                          <div class="form-check form-check-flat">
                            <label class="form-check-label">
                              <input class="checkbox" type="checkbox" /> Ticket-0014 </label>
                          </div>
                         
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title">Service Engineers</p>
                    <ul class="icon-data-list">
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face1.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face2.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face3.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face4.jpg" alt="user" />
                          <div>
                           <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face5.jpg" alt="user" />
                          <div>
                           <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title">Top Consumer</p>
                    <ul class="icon-data-list">
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face1.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face2.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face3.jpg" alt="user" />
                          <div>
                            <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face4.jpg" alt="user" />
                          <div>
                           <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          <img src="../assets/images/faces/face5.jpg" alt="user" />
                          <div>
                           <p class="text-info mb-1">Abc</p>
                            <p class="mb-0">Category: Abc</p>
                            <small>Area: Abc</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END OF CALENDAR ROW WITH CHECKLIST AND ENGINEERS --> */}


         



       
</Base>
  )
}

export default Dashboard