import Base from "./Base";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Consumer } from "./service/Consumer";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea"; // Corrected import
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import "./styles.css"; //
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const Consumers = () => {
  const emptyConsumer = {
    id: null,
    consumerId: null,
    productId: null,
    modelId: null,
    productName: "",
    modelName: "",
    phoneNo: null,
    emailId: "",
    address: "",
    password: "",
    confirmPassword: "",
    createDateAndTime: null,
    updateDateAndTime: null,
  };

  const [consumer, setConsumer] = useState(emptyConsumer);

  const [selectedDescription, setSelectedDescription] = useState(
    consumer.consumerDescription
  );
  const [consumers, setConsumers] = useState([]);
  const [consumerDialog, setConsumerDialog] = useState(false);
  const [deleteConsumerDialog, setDeleteConsumerDialog] = useState(false);
  const [deleteConsumersDialog, setDeleteConsumersDialog] = useState(false);
  const [reorderColumnsDialog, setReorderColumnsDialog] = useState(false);
  const [selectedConsumers, setSelectedConsumers] = useState([]);
  const [selectedImage, setSelectedImage] = useState("/assets/images/logo.png"); // Default image path
  const [submitted, setSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    consumer.selectedCountry || ""
  );
  const [selectedState, setSelectedState] = useState(
    consumer.selectedState || ""
  );
  const [selectedCity, setSelectedCity] = useState(consumer.selectedCity || "");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const consumerRemark = consumer.consumerRemark || "";
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);

  const [selectedModels, setSelectedModels] = useState([]);

  const [filtersVisible, setFiltersVisible] = useState(true);

  const toggleFilters = () => {
    setFiltersVisible((prevState) => !prevState);
  };

  const productData = [
    {
      productId: '434',
      modelId: '721',
      productName: 'Product A',
      models: [
        { modelId: '721', modelName: 'Model 12sc' },
        { modelId: '722', modelName: 'Model 15xx' },
      ],
      ticketStatus: 'Open',
    },
    {
      productId: '302',
      modelId: '723',
      productName: 'Product B',
      models: [
        { modelId: '723', modelName: 'Model 18gh' },
        { modelId: '724', modelName: 'Model 20zz' },
      ],
      ticketStatus: 'Open',
    },
  ];

  const handleProductChange = (e) => {
    const selectedProductIds = e.value.map(item => item.productId);
    const selectedProducts = productData.filter(p => selectedProductIds.includes(p.productId));

    setSelectedProducts(selectedProducts.map(p => p.productName));
    const models = selectedProducts.flatMap(p => p.models);
    setAvailableModels(models);
    setConsumer(prevConsumer => ({
      ...prevConsumer,
      productId: selectedProductIds.join(', '),
      productName: selectedProducts.map(p => p.productName).join(', '),
      models: models,
    }));
  };

  const handleModelChange = (e) => {
    const selectedModelIds = e.value.map(item => item.modelId);
    const selectedModels = availableModels.filter(m => selectedModelIds.includes(m.modelId));

    setSelectedModels(selectedModels.map(m => m.modelName));
    setConsumer(prevConsumer => ({
      ...prevConsumer,
      modelId: selectedModelIds.join(', '),
      modelName: selectedModels.map(m => m.modelName).join(', '),
    }));
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    consumerId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    productId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modelId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phoneNo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    emailId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCountry: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedState: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    password: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    confirmPassword: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const models = productData
        .filter(p => selectedProducts.includes(p.productName))
        .flatMap(p => p.models);
      setAvailableModels(models);
    } else {
      setAvailableModels([]);
    }
  }, [selectedProducts]);

  useEffect(() => {
    // Load cities when selectedState changes
    if (selectedProducts) {
      const modelsData = models.find(
        (models) => models.name === selectedModels
      );
    }
  }, [selectedModel, models]);

  useEffect(() => {
    // Load states when selectedCountry changes
    if (selectedCountry) {
      const countryData = data.countries.find(
        (country) => country.name === selectedCountry
      );
      setStates(countryData ? countryData.states : []);
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Load cities when selectedState changes
    if (selectedState) {
      const stateData = states.find((state) => state.name === selectedState);
      setCities(stateData ? stateData.cities : []);
    }
  }, [selectedState, states]);

  const [availableColumns, setAvailableColumns] = useState([
    { label: "Consumer ID", id: "productId" },
    { label: "First Name", id: "firstName" },
    { label: "Last Name", id: "lastName" },
    { label: "Email Id", id: "emailId" },
    { label: "Phone No", id: "phoneNo" },
    { label: "Product ID", id: "modelId" },
    { label: "Product Name", id: "productName" },
    { label: "Model Name", id: "modelName" },
    { label: "Address", id: "address" },
    { label: "Country", id: "selectedCountry" },
    { label: "State", id: "selectedState" },
    { label: "City", id: "selectedCity" },
    { label: "Password", id: "password" },
    { label: "Confirm Password", id: "confirmPassword" },
  ]);

  const [selectedColumns, setSelectedColumns] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    Consumer.getConsumers().then((data) => setConsumers(data));
    setSelectedColumns(availableColumns);
    setSource(availableColumns);
  }, []);

  const onDateChange = (e, fieldName) => {
    setConsumer({
      ...consumer,
      [fieldName]: e.value,
    });
  };

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDescriptionChange = (e) => {
    setSelectedDescription(e.value);
    onInputChange({ target: { value: e.value } }, "ticketDescription");
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setConsumer((prevConsumer) => ({
      ...prevConsumer,
      selectedCountry: e.target.value, // Update the productImage field in the ticket state
    }));
    setSelectedState(""); // Reset state and city when country changes
    setSelectedCity("");
  };

  const handleUpload = (e) => {
    if (e.files && e.files.length > 0) {
      const imageUrl = URL.createObjectURL(e.files[0]); // Get the selected image URL
      setConsumer((prevConsumer) => ({
        ...prevConsumer,
        productImage: imageUrl, // Update the productImage field in the ticket state
      }));
    }
    onInputChange(
      { target: { id: "productImages", value: e.files } },
      "productImages"
    );
  };

  const handleInputChange = (e, field) => {
    setConsumer((prevConsumer) => ({
      ...prevConsumer,
      [field]: e.target.value, // Update other fields in the ticket state
    }));
  };

  
    const handleStateChange = (e) => {
      setSelectedState(e.target.value);
      
      setConsumer((prevConsumer) => ({
        ...prevConsumer,
        selectedState: e.target.value, // Update the productImage field in the ticket state
      }));
      setSelectedCity(""); // Reset city when state changes
    };

    const handleCityChange = (e) => {
      setSelectedCity(e.target.value);
  
      setConsumer((prevConsumer) => ({
        ...prevConsumer,
        selectedCity: e.target.value, // Update the productImage field in the ticket state
      }));
    };

  const handleColumnChange = (e) => {
    setSource(e.source);
    setTarget(e.target);
  };

  const saveConsumer = () => {
    setSubmitted(true);

    let _consumers = [...consumers];
    let _consumer = { ...consumer };

    if (consumer.id) {
      const index = _consumers.findIndex((t) => t.id === consumer.id);
      _consumers[index] = _consumer;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Consumer Updated",
        life: 3000,
      });
    } else {
      _consumer.id = new Date().getTime(); // Unique ID generator
      _consumers.push(_consumer);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Consumer Created",
        life: 3000,
      });
    }

    setConsumers(_consumers);
    setConsumerDialog(false);
    setConsumer(emptyConsumer);
    setSelectedCountry(emptyConsumer);
    setSelectedState(emptyConsumer);
    
  };

  const hideDialog = () => {
    setConsumerDialog(false);
    setConsumer(emptyConsumer);
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setSelectedModels([]);
    setSelectedProducts([]);
  };

  

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`/assets/images/${rowData.productImages}`}
        alt={rowData.productImages}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };
  const deleteConsumer = () => {
    let _consumers = consumers.filter((val) => val.id !== consumer.id);
    setConsumers(_consumers);
    setDeleteConsumerDialog(false);
    setConsumer(emptyConsumer);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Consumer Deleted",
      life: 3000,
    });
  };

  const deleteSelectedConsumers = () => {
    let _consumers = consumers.filter(
      (val) => !selectedConsumers.includes(val)
    );
    setConsumers(_consumers);
    setDeleteConsumersDialog(false);
    setSelectedConsumers([]);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Consumer Deleted",
      life: 3000,
    });
  };

  const onSelectionChange = (e) => {
    setSelectedConsumers(e.value);
  };

  const editConsumer = (rowData) => {
    setConsumer({ ...rowData });
    setSelectedCountry(rowData.selectedCountry || "");
    setSelectedState(rowData.selectedState || "");
    setSelectedCity(rowData.selectedCity || "");
    setConsumerDialog(true);
  };
  const data = {
    countries: [
      {
        name: "India",
        states: [
          { name: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
          { name: "Karnataka", cities: ["Bengaluru", "Mysuru"] },
        ],
      },
      {
        name: "USA",
        states: [
          { name: "California", cities: ["Los Angeles", "San Francisco"] },
          { name: "New York", cities: ["New York City", "Buffalo"] },
        ],
      },
    ],
  };

  const productdata = {
    products: [
      {
        name: "Product A",
        models: [{ name: "ModelA123" }, { name: "ModelB234" }],
      },
      {
        name: "Product B",
        models: [{ name: "ModelC345" }, { name: "ModelD456" }],
      },
    ],
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="custom-button-edit p-button-rounded p-button-sm"
          onClick={() => editConsumer(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="custom-button-delete p-button-rounded p-button-sm"
          onClick={() => {
            setConsumer(rowData);
            setDeleteConsumerDialog(true);
          }}
        />
      </div>
    );
  };

  const openReorder = () => {
    setReorderColumnsDialog(true);
  };

  const newConsumer = () => {
    setConsumer(emptyConsumer);
    setConsumerDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          className="button-new"
          onClick={newConsumer}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="button-delete"
          onClick={() => setDeleteConsumersDialog(true)}
          disabled={!selectedConsumers.length} // Disable if no tickets selected
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="button-export mx-2"
          onClick={() => dt.current.exportCSV()}
        />
        <Button
          label="Reorder Columns"
          icon="pi pi-sort"
          className="button-reorder mx-2"
          onClick={openReorder}
        />

        <Button className="button-filters" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
    );
  };

  const header = (
    <div className="flex items-center justify-between gap-2">
      <h4 className="m-0">Consumers</h4>
      <div className="flex items-center p-input-icon p-input-icon-left">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilterValue(e.target.value)}
          placeholder="Search..."
          className="ml-2"
        />
      </div>
    </div>
  );

  const consumerDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => hideDialog(false)}
        className="cancel-button" // Apply CSS class for Cancel button
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={saveConsumer}
        className="edit-button" // Apply CSS class for Edit button
      />
    </React.Fragment>
  );

  const deleteConsumerDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteConsumerDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteConsumer} />
    </React.Fragment>
  );

  const deleteConsumersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteConsumersDialog(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={deleteSelectedConsumers}
      />
    </React.Fragment>
  );
  // ===================================SAVE REORDER COMPONENTS==========================================
  const saveReorderColumn = () => {
    setSelectedColumns(target);
    setReorderColumnsDialog(false);
  };

  const reorderColumnsDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => setReorderColumnsDialog(false)}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveReorderColumn} />
    </React.Fragment>
  );
  // ===================================END OF SAVE REORDER COMPONENTS==========================================
  const onInputChange = (e, field) => {
    const val = e.target.value;
    setConsumer({ ...consumer, [field]: val });
  };

  // ===================================VIEW COMPONENTS==========================================
  return (
    <Base>
      <div>
        <Toast ref={toast} />

        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />

        {selectedColumns && selectedColumns.length > 0 ? (
          <DataTable
            ref={dt}
            value={consumers}
            selection={selectedConsumers}
            onSelectionChange={onSelectionChange}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            header={header}
            globalFilter={globalFilterValue}
            filters={filtersVisible ? filters : null}
            filterDisplay={filtersVisible ? "row" : "none"}
            emptyMessage="No consumer found."
          >
            <Column
              selectionMode="multiple"
              exportable={false}
              style={{ textAlign: "center" }}
            />

            {selectedColumns.map((col) => (
              <Column
                key={col.id}
                field={col.id}
                header={col.label}
                sortable
                filter
                filterPlaceholder="Search"
                style={{ minWidth: "12rem", textAlign: "left" }}
                headerStyle={{ textAlign: "left" }}
              />
            ))}
            <Column
              body={imageBodyTemplate}
              header="Product Image"
              style={{ width: "100px", textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
            />
            <Column
              body={rowActionsBodyTemplate}
              exportable={false}
              className="mx-2"
              style={{ minWidth: "12rem", textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
            />
          </DataTable>
        ) : (
          <div className="card">
            <h4 style={{ textAlign: "center", padding: "1rem" }}>
              No Columns have been selected{" "}
            </h4>
          </div>
        )}

        <Dialog
          visible={consumerDialog}
          style={{ width: "700px" }}
          header={
            consumer.id ? "Edit Consumer Details" : "Create Consumer Details"
          }
          modal
          className="p-fluid dialog custom-dialog-header"
          footer={consumerDialogFooter}
          onHide={() => hideDialog(false)}
        >
          <div className="form-grid">
            <div className="field">
              <label htmlFor="firstName" className="bold-label">
                First Name
              </label>
              <InputText
                id="productId"
                value={consumer.firstName}
                onChange={(e) => onInputChange(e, "firstName")}
              />
            </div>

            <div className="field">
              <label htmlFor="lastName" className="bold-label">
                Last Name
              </label>
              <InputText
                id="productId"
                value={consumer.lastName}
                onChange={(e) => onInputChange(e, "lastName")}
              />
            </div>

            <div className="field">
              <label htmlFor="emailId" className="bold-label">
                Email Id
              </label>
              <InputText
                id="emailId"
                value={consumer.emailId}
                onChange={(e) => onInputChange(e, "emailId")}
              />
            </div>

            <div className="field">
              <label htmlFor="phoneNo" className="bold-label">
                Phone No
              </label>
              <InputText
                id="phoneNo"
                value={consumer.phoneNo}
                onChange={(e) => onInputChange(e, "phoneNo")}
              />
            </div>

            <div className="field">
              <label htmlFor="address" className="bold-label">
                Address
              </label>
              <InputText
                id="address"
                value={consumer.address}
                onChange={(e) => onInputChange(e, "address")}
              />
            </div>

            <div className="field">
              <label htmlFor="country" className="bold-label">
                Country
              </label>
              <select
                id="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {data.countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="field">
              <label htmlFor="state" className="bold-label">
                State
              </label>
              <select
                id="state"
                value={selectedState}
                onChange={handleStateChange}
                disabled={!selectedCountry}
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="field">
            <label htmlFor="city" className="bold-label">
              City
            </label>
            <select
              id="city"
              value={selectedCity}
              onChange={handleCityChange}
              disabled={!selectedState}
            >
              <option value="">Select City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>

            <div className="field">
              <label htmlFor="password" className="bold-label">
                Password
              </label>
              <Password
                id="password"
                value={consumer.password}
                onChange={(e) => onInputChange(e, "password")}
                toggleMask
              />
            </div>

            <div className="field">
              <label htmlFor="confirmPassword" className="bold-label">
                Confirm Password
              </label>
              <Password
                id="password"
                value={consumer.confirmPassword}
                onChange={(e) => onInputChange(e, "confirmPassword")}
                toggleMask
              />
            </div>

            <div className="field">
        <label htmlFor="products" className="bold-label">
          Product Name
        </label>
        <MultiSelect
          id="products"
          options={productData}
          optionLabel="productName"
          value={productData.filter(p => selectedProducts.includes(p.productName))}
          onChange={handleProductChange}
          placeholder="Select Products"
          display="chip"
        />
      </div>

      <div className="field">
        <label htmlFor="models" className="bold-label">
          Model Name
        </label>
        <MultiSelect
          id="models"
          options={availableModels}
          optionLabel="modelName"
          value={availableModels.filter(m => selectedModels.includes(m.modelName))}
          onChange={handleModelChange}
          placeholder="Select Models"
          display="chip"
        />
      </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteConsumerDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteConsumerDialogFooter}
          onHide={() => setDeleteConsumerDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {consumer && (
              <span>
                Are you sure you want to delete consumers{" "}
                <b>{consumer.consumerRaiseUserId}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteConsumersDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteConsumersDialogFooter}
          onHide={() => setDeleteConsumersDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to delete the selected consumers?</span>
          </div>
        </Dialog>

        <Dialog
          visible={reorderColumnsDialog}
          style={{ width: "700px" }}
          header="Reorder Columns"
          modal
          footer={reorderColumnsDialogFooter}
          onHide={() => setReorderColumnsDialog(false)}
        >
          <PickList
            source={source}
            target={target}
            onChange={handleColumnChange}
            itemTemplate={(item) => item.label}
            sourceHeader="Available Columns"
            targetHeader="Selected Columns"
          />
        </Dialog>
      </div>
    </Base>
  );
};

export default Consumers;
