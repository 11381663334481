import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ConsumerBase from "./ConsumerBase";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const ConsumerDashboard = () => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);

  return (
    <ConsumerBase>
      <div class="row">
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Open Ticket</h4>
              <div class="list-wrapper pt-2">
                <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
                  <li>
                    <label class="form-check-label">
                      <p className="pi pi-external-link" onClick={() => setVisible(true)}> Ticket-0007</p>
                      </label>
                    <Dialog
                      header="Ticket-0007"
                      visible={visible}
                      style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible) return;
                        setVisible(false);
                      }}
                    >
                      <p className="m-0">
                      Product Name: Product A<br />
                      Model Name: Model 12sc<br />
                      Ticket Description: Noise Problem<br />
                      Ticket Remark: Remark1<br />
                      Available Contact Date & Time: 18/07/2024<br />
                      Address: Address A B<br />
                      Country: India<br />
                      State: Tamil Nadu<br />
                      City: Coimbatore<br />
                      </p>
                    </Dialog>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Ticket Assign</h4>
              <div class="list-wrapper pt-2">
                <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
                <li>
                    <label class="form-check-label">
                      <p className="pi pi-external-link" onClick={() => setVisible2(true)}> Ticket-0009</p>
                      </label>
                    <Dialog
                      header="Ticket-0009"
                      visible={visible2}
                      style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible2) return;
                        setVisible2(false);
                      }}
                    >
                      <p className="m-0">
                      Product Name: Product B<br />
                      Model Name: Model 52sc<br />
                      Ticket Description: Noise Problem<br />
                      Ticket Remark: Remark3<br />
                      Available Contact Date & Time: 18/07/2024<br />
                      Address: Address A C B<br />
                      Country: India<br />
                      State: Tamil Nadu<br />
                      City: Coimbatore<br />
                      </p>
                    </Dialog>
                </li>

                <li>
                    <label class="form-check-label">
                      <p className="pi pi-external-link" onClick={() => setVisible3(true)}> Ticket-0010</p>
                      </label>
                    <Dialog
                      header="Ticket-0010"
                      visible={visible3}
                      style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible3) return;
                        setVisible3(false);
                      }}
                    >
                      <p className="m-0">
                      Product Name: Product B<br />
                      Model Name: Model 52sc<br />
                      Ticket Description: Noise Problem<br />
                      Ticket Remark: Remark3<br />
                      Available Contact Date & Time: 18/07/2024<br />
                      Address: Address A C B<br />
                      Country: India<br />
                      State: Tamil Nadu<br />
                      City: Coimbatore<br />
                      </p>
                    </Dialog>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Ticket complete</h4>
              <div class="list-wrapper pt-2">
                <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
                <li>
                    <label class="form-check-label">
                      <p className="pi pi-external-link" onClick={() => setVisible4(true)}> Ticket-0011</p>
                      </label>
                    <Dialog
                      header="Ticket-0011"
                      visible={visible4}
                      style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible4) return;
                        setVisible4(false);
                      }}
                    >
                      <p className="m-0">
                      Product Name: Product B<br />
                      Model Name: Model 52sc<br />
                      Ticket Description: Noise Problem<br />
                      Ticket Remark: Remark3<br />
                      Available Contact Date & Time: 18/07/2024<br />
                      Address: Address A C B<br />
                      Country: India<br />
                      State: Tamil Nadu<br />
                      City: Coimbatore<br />
                      </p>
                    </Dialog>
                </li>

                <li>
                    <label class="form-check-label">
                      <p className="pi pi-external-link" onClick={() => setVisible5(true)}> Ticket-0012</p>
                      </label>
                    <Dialog
                      header="Ticket-0012"
                      visible={visible5}
                      style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible5) return;
                        setVisible5(false);
                      }}
                    >
                      <p className="m-0">
                      Product Name: Product B<br />
                      Model Name: Model 52sc<br />
                      Ticket Description: Noise Problem<br />
                      Ticket Remark: Remark3<br />
                      Available Contact Date & Time: 18/07/2024<br />
                      Address: Address A C B<br />
                      Country: India<br />
                      State: Tamil Nadu<br />
                      City: Coimbatore<br />
                      </p>
                    </Dialog>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConsumerBase>
  );
};

export default ConsumerDashboard;
