export const Consumer = {
    getConsumersData() {
        return [
            {
                "id": 1,
                "consumerId": 101,
                "productId": 202,
                "modelId": 303,
                "firstName": "John",
                "lastName": "Smith",
                "emailId": "dsg@gmail.com",
                "phoneNo": "9986545890",
                "productName": "Product A",
                "modelName": "ModelA123",
                "address": "123 Maple Street, New York, NY",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "password": "password1",
                "confirmPassword": "password1",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 2,
                "consumerId": 102,
                "productId": 203,
                "modelId": 304,
                "productName": "Product B",
                "modelName": "ModelB234",
                "firstName": "Jane",
                "lastName": "Johnson",
                "emailId": "dsddg@gmail.com",
                "phoneNo": "9983457689",
                "address": "456 Oak Avenue, Los Angeles, CA",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Chennai",
                "password": "password2",
                "confirmPassword": "password2",
                "createDateAndTime": "08/13/2024 14:10",
                "updateDateAndTime": "08/13/2024 14:10"
            },
            {
                "id": 3,
                "consumerId": 103,
                "productId": 204,
                "modelId": 305,
                "productName": "Product C",
                "modelName": "ModelC345",
                "firstName": "Alex",
                "lastName": "Williams",
                "emailId": "dsdcdsg@gmail.com",
                "phoneNo": "9556890321",
                "address": "789 Pine Street, Chicago, IL",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "password": "password3",
                "confirmPassword": "password3",
                "createDateAndTime": "08/14/2024 12:30",
                "updateDateAndTime": "08/14/2024 12:30"
            },
            {
                "id": 4,
                "consumerId": 104,
                "productId": 205,
                "modelId": 306,
                "productName": "Product D",
                "modelName": "ModelD456",
                "firstName": "Jones",
                "lastName": "Ramasubbu",
                "emailId": "dsgsdcscrt@gmail.com",
                "phoneNo": "8364567289",
                "address": "101 Elm Street, Houston, TX",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "password": "password4",
                "confirmPassword": "password4",
                "createDateAndTime": "08/15/2024 11:25",
                "updateDateAndTime": "08/15/2024 11:25"
            },
            {
                "id": 5,
                "consumerId": 105,
                "productId": 206,
                "modelId": 307,
                "productName": "Product E",
                "modelName": "ModelE567",
                "firstName": "Chris",
                "lastName": "Brown",
                "emailId": "dgfdgsg@gmail.com",
                "phoneNo": "9987654987",
                "address": "202 Maple Street, Miami, FL",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "password": "password5",
                "confirmPassword": "password5",
                "createDateAndTime": "08/16/2024 15:40",
                "updateDateAndTime": "08/16/2024 15:40"
            },
            {
                "id": 6,
                "consumerId": 106,
                "productId": 207,
                "modelId": 308,
                "productName": "Product F",
                "modelName": "ModelF678",
                "firstName": "Katie",
                "lastName": "Davis",
                "emailId": "abc@gmail.com",
                "phoneNo": "0918274689",
                "address": "303 Oak Avenue, Seattle, WA",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "password": "password6",
                "confirmPassword": "password6",
                "createDateAndTime": "08/17/2024 10:50",
                "updateDateAndTime": "08/17/2024 10:50"
            },
            {
                "id": 7,
                "consumerId": 107,
                "productId": 208,
                "modelId": 309,
                "productName": "Product G",
                "modelName": "ModelG789",
                "firstName": "Michael",
                "lastName": "Miller",
                "emailId": "xyz@gmail.com",
                "phoneNo": "1234567890",
                "address": "404 Birch Road, Boston, MA",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Madurai",
                "password": "password7",
                "confirmPassword": "password7",
                "createDateAndTime": "08/18/2024 09:15",
                "updateDateAndTime": "08/18/2024 09:15"
            },
            {
                "id": 8,
                "consumerId": 108,
                "productId": 209,
                "modelId": 310,
                "productName": "Product H",
                "modelName": "ModelH890",
                "firstName": "Sarah",
                "lastName": "Wilson",
                "emailId": "cde@gmail.com",
                "phoneNo": "9934779267",
                "address": "505 Cedar Lane, San Diego, CA",
                "selectedCountry": "Italy",
                "selectedState": "Lazio",
                "selectedCity": "Rome",
                "password": "password8",
                "confirmPassword": "password8",
                "createDateAndTime": "08/19/2024 16:05",
                "updateDateAndTime": "08/19/2024 16:05"
            },
            {
                "id": 9,
                "consumerId": 109,
                "productId": 210,
                "modelId": 311,
                "productName": "Product I",
                "modelName": "ModelI901",
                "firstName": "David",
                "lastName": "Moore",
                "emailId": "fyu@gmail.com",
                "phoneNo": "7864598026",
                "address": "606 Maple Avenue, Denver, CO",
                "selectedCountry": "Spain",
                "selectedState": "Madrid",
                "selectedCity": "Madrid",
                "password": "password9",
                "confirmPassword": "password9",
                "createDateAndTime": "08/20/2024 13:45",
                "updateDateAndTime": "08/20/2024 13:45"
            },
            {
                "id": 10,
                "consumerId": 110,
                "productId": 211,
                "modelId": 312,
                "productName": "Product J",
                "modelName": "ModelJ012",
                "firstName": "Laura",
                "lastName": "Taylor",
                "emailId": "asfsd@gmail.com",
                "phoneNo": "6667893290",
                "address": "707 Pine Street, Phoenix, AZ",
                "selectedCountry": "Brazil",
                "selectedState": "São Paulo",
                "selectedCity": "São Paulo",
                "password": "password10",
                "confirmPassword": "password10",
                "createDateAndTime": "08/21/2024 12:35",
                "updateDateAndTime": "08/21/2024 12:35"
            },
            {
                "id": 11,
                "consumerId": 111,
                "productId": 212,
                "modelId": 313,
                "productName": "Product K",
                "modelName": "ModelK123",
                "firstName": "James",
                "lastName": "Anderson",
                "emailId": "yeg@gmail.com",
                "phoneNo": "9128340576",
                "address": "808 Oak Street, Dallas, TX",
                "selectedCountry": "Argentina",
                "selectedState": "Buenos Aires",
                "selectedCity": "Buenos Aires",
                "password": "password11",
                "confirmPassword": "password11",
                "createDateAndTime": "08/22/2024 11:30",
                "updateDateAndTime": "08/22/2024 11:30"
            },
            {
                "id": 12,
                "consumerId": 112,
                "productId": 213,
                "modelId": 314,
                "productName": "Product L",
                "modelName": "ModelL234",
                "firstName": "Emma",
                "lastName": "Thomas",
                "emailId": "xys@gmail.com",
                "phoneNo": "9988567432",
                "address": "909 Cedar Lane, San Jose, CA",
                "selectedCountry": "Chile",
                "selectedState": "Santiago",
                "selectedCity": "Santiago",
                "password": "password12",
                "confirmPassword": "password12",
                "createDateAndTime": "08/23/2024 10:25",
                "updateDateAndTime": "08/23/2024 10:25"
            },
            {
                "id": 13,
                "consumerId": 113,
                "productId": 214,
                "modelId": 315,
                "productName": "Product M",
                "modelName": "ModelM345",
                "firstName": "Robert",
                "lastName": "Jackson",
                "emailId": "tyu@gmail.com",
                "phoneNo": "9743654321",
                "address": "1010 Birch Road, Austin, TX",
                "selectedCountry": "Colombia",
                "selectedState": "Bogotá",
                "selectedCity": "Bogotá",
                "password": "password13",
                "confirmPassword": "password13",
                "createDateAndTime": "08/24/2024 09:15",
                "updateDateAndTime": "08/24/2024 09:15"
            },
            {
                "id": 14,
                "consumerId": 114,
                "productId": 215,
                "modelId": 316,
                "productName": "Product N",
                "modelName": "ModelN456",
                "firstName": "Olivia",
                "lastName": "White",
                "emailId": "ahf@gmail.com",
                "phoneNo": "9087162534",
                "address": "1111 Maple Avenue, Philadelphia, PA",
                "selectedCountry": "Peru",
                "selectedState": "Lima",
                "selectedCity": "Lima",
                "password": "password14",
                "confirmPassword": "password14",
                "createDateAndTime": "08/25/2024 08:05",
                "updateDateAndTime": "08/25/2024 08:05"
            },
            {
                "id": 15,
                "consumerId": 115,
                "productId": 216,
                "modelId": 317,
                "productName": "Product O",
                "modelName": "ModelO567",
                "firstName": "Daniel",
                "lastName": "Harris",
                "emailId": "rps@gmail.com",
                "phoneNo": "5784906322",
                "address": "1212 Oak Street, San Francisco, CA",
                "selectedCountry": "Venezuela",
                "selectedState": "Caracas",
                "selectedCity": "Caracas",
                "password": "password15",
                "confirmPassword": "password15",
                "createDateAndTime": "08/26/2024 07:50",
                "updateDateAndTime": "08/26/2024 07:50"
            },
            {
                "id": 16,
                "consumerId": 116,
                "productId": 217,
                "modelId": 318,
                "productName": "Product P",
                "modelName": "ModelP678",
                "firstName": "Sophia",
                "lastName": "Martin",
                "emailId": "qte@gmail.com",
                "phoneNo": "1234560987",
                "address": "1313 Pine Street, Seattle, WA",
                "selectedCountry": "South Africa",
                "selectedState": "Gauteng",
                "selectedCity": "Johannesburg",
                "password": "password16",
                "confirmPassword": "password16",
                "createDateAndTime": "08/27/2024 06:30",
                "updateDateAndTime": "08/27/2024 06:30"
            },
            {
                "id": 17,
                "consumerId": 117,
                "productId": 218,
                "modelId": 319,
                "productName": "Product Q",
                "modelName": "ModelQ789",
                "firstName": "Matthew",
                "lastName": "Thompson",
                "emailId": "ryt@gmail.com",
                "phoneNo": "7890654321",
                "address": "1414 Cedar Lane, Miami, FL",
                "selectedCountry": "Egypt",
                "selectedState": "Cairo",
                "selectedCity": "Cairo",
                "password": "password17",
                "confirmPassword": "password17",
                "createDateAndTime": "08/28/2024 05:15",
                "updateDateAndTime": "08/28/2024 05:15"
            },
            {
                "id": 18,
                "consumerId": 118,
                "productId": 219,
                "modelId": 320,
                "productName": "Product R",
                "modelName": "ModelR890",
                "firstName": "Isabella",
                "lastName": "Garcia",
                "emailId": "qtte@gmail.com",
                "phoneNo": "0987123456",
                "address": "1515 Birch Road, Dallas, TX",
                "selectedCountry": "Japan",
                "selectedState": "Tokyo",
                "selectedCity": "Tokyo",
                "password": "password18",
                "confirmPassword": "password18",
                "createDateAndTime": "08/29/2024 04:00",
                "updateDateAndTime": "08/29/2024 04:00"
            },
            {
                "id": 19,
                "consumerId": 119,
                "productId": 220,
                "modelId": 321,
                "productName": "Product S",
                "modelName": "ModelS901",
                "emailId": "arert@gmail.com",
                "phoneNo": "6789054321",
                "address": "1616 Oak Street, Denver, CO",
                "firstName": "Andrew",
                "lastName": "Martinez",
                
                "selectedCountry": "South Korea",
                "selectedState": "Seoul",
                "selectedCity": "Seoul",
                "password": "password19",
                "confirmPassword": "password19",
                "createDateAndTime": "08/30/2024 03:10",
                "updateDateAndTime": "08/30/2024 03:10"
            },
            {
                "id": 20,
                "consumerId": 120,
                "productId": 221,
                "modelId": 322,
                "productName": "Product T",
                "modelName": "ModelT012",
                "firstName": "Ava",
                "lastName": "Robinson",
                "emailId": "dry@gmail.com",
                "phoneNo": "0987654312",
                "address": "1717 Maple Avenue, San Jose, CA",
                "selectedCountry": "China",
                "selectedState": "Beijing",
                "selectedCity": "Beijing",
                "password": "password20",
                "confirmPassword": "password20",
                "createDateAndTime": "08/31/2024 02:25",
                "updateDateAndTime": "08/31/2024 02:25"
            },
            {
                "id": 21,
                "consumerId": 121,
                "productId": 222,
                "modelId": 323,
                "productName": "Product U",
                "modelName": "ModelU123",
                "firstName": "Joshua",
                "lastName": "Clark",
                "emailId": "drds@gmail.com",
                "phoneNo": "1234567890",
                "address": "1818 Cedar Lane, Austin, TX",
                "selectedCountry": "India",
                "selectedState": "Maharashtra",
                "selectedCity": "Mumbai",
                "password": "password21",
                "confirmPassword": "password21",
                "createDateAndTime": "09/01/2024 01:40",
                "updateDateAndTime": "09/01/2024 01:40"
            },
            {
                "id": 22,
                "consumerId": 122,
                "productId": 223,
                "modelId": 324,
                "productName": "Product V",
                "modelName": "ModelV234",
                "firstName": "Mia",
                "lastName": "Rodriguez",
                "emailId": "qetu@gmail.com",
                "phoneNo": "1234567890",
                "address": "1919 Pine Street, Philadelphia, PA",
                "selectedCountry": "Brazil",
                "selectedState": "Rio de Janeiro",
                "selectedCity": "Rio de Janeiro",
                "password": "password22",
                "confirmPassword": "password22",
                "createDateAndTime": "09/02/2024 00:55",
                "updateDateAndTime": "09/02/2024 00:55"
            },
            {
                "id": 23,
                "consumerId": 123,
                "productId": 224,
                "modelId": 325,
                "productName": "Product W",
                "modelName": "ModelW345",
                "firstName": "Ethan",
                "lastName": "Lewis",
                "emailId": "afty@gmail.com",
                "phoneNo": "9807654421",
                "address": "2020 Oak Avenue, San Francisco, CA",
                "selectedCountry": "Argentina",
                "selectedState": "Cordoba",
                "selectedCity": "Cordoba",
                "password": "password23",
                "confirmPassword": "password23",
                "createDateAndTime": "09/03/2024 22:10",
                "updateDateAndTime": "09/03/2024 22:10"
            },
            {
                "id": 24,
                "consumerId": 124,
                "productId": 225,
                "modelId": 326,
                "productName": "Product X",
                "modelName": "ModelX456",
                "firstName": "Charlotte",
                "lastName": "Lee",
                "emailId": "dsgdtr@gmail.com",
                "phoneNo": "9081726354",
                "address": "2121 Birch Road, Seattle, WA",
                "selectedCountry": "Chile",
                "selectedState": "Valparaíso",
                "selectedCity": "Valparaíso",
                "password": "password24",
                "confirmPassword": "password24",
                "createDateAndTime": "09/04/2024 21:20",
                "updateDateAndTime": "09/04/2024 21:20"
            },
            {
                "id": 25,
                "consumerId": 125,
                "productId": 226,
                "modelId": 327,
                "productName": "Product Y",
                "modelName": "ModelY567",
                "firstName": "Ryan",
                "lastName": "Walker",
                "emailId": "dsggdyuy@gmail.com",
                "phoneNo": "0987654321",
                "address": "2222 Maple Street, Miami, FL",
                "selectedCountry": "Colombia",
                "selectedState": "Antioquia",
                "selectedCity": "Medellín",
                "password": "password25",
                "confirmPassword": "password25",
                "createDateAndTime": "09/05/2024 20:30",
                "updateDateAndTime": "09/05/2024 20:30"
            },
            {
                "id": 26,
                "consumerId": 126,
                "productId": 227,
                "modelId": 328,
                "productName": "Product Z",
                "modelName": "ModelZ678",
                "firstName": "Amelia",
                "lastName": "Hall",
                "emailId": "dsgdrr@gmail.com",
                "phoneNo": "1234789056",
                "address": "2323 Pine Street, Denver, CO",
                "selectedCountry": "South Africa",
                "selectedState": "Western Cape",
                "selectedCity": "Cape Town",
                "password": "password26",
                "confirmPassword": "password26",
                "createDateAndTime": "09/06/2024 19:45",
                "updateDateAndTime": "09/06/2024 19:45"
            },
            {
                "id": 27,
                "consumerId": 127,
                "productId": 228,
                "modelId": 329,
                "productName": "Product AA",
                "modelName": "ModelAA789",
                "firstName": "William",
                "lastName": "Allen",
                "emailId": "dssrteteg@gmail.com",
                "phoneNo": "1256789034",
                "address": "2424 Oak Avenue, San Jose, CA",
                "selectedCountry": "Egypt",
                "selectedState": "Giza",
                "selectedCity": "Giza",
                "password": "password27",
                "confirmPassword": "password27",
                "createDateAndTime": "09/07/2024 18:00",
                "updateDateAndTime": "09/07/2024 18:00"
            },
            {
                "id": 28,
                "consumerId": 128,
                "productId": 229,
                "modelId": 330,
                "productName": "Product BB",
                "modelName": "ModelBB890",
                "firstName": "Harper",
                "lastName": "Young",
                "emailId": "dsggdd@gmail.com",
                "phoneNo": "1234589078",
                "address": "2525 Birch Road, Austin, TX",
                "selectedCountry": "Japan",
                "selectedState": "Osaka",
                "selectedCity": "Osaka",
                "password": "password28",
                "confirmPassword": "password28",
                "createDateAndTime": "09/08/2024 17:15",
                "updateDateAndTime": "09/08/2024 17:15"
            },
            {
                "id": 29,
                "consumerId": 129,
                "productId": 230,
                "modelId": 331,
                "productName": "Product CC",
                "modelName": "ModelCC901",
                "firstName": "Thomas",
                "lastName": "King",
                "emailId": "dddsg@gmail.com",
                "phoneNo": "0987654321",
                "address": "2626 Maple Street, Philadelphia, PA",
                "selectedCountry": "China",
                "selectedState": "Shanghai",
                "selectedCity": "Shanghai",
                "password": "password29",
                "confirmPassword": "password29",
                "createDateAndTime": "09/09/2024 16:30",
                "updateDateAndTime": "09/09/2024 16:30"
            },
            {
                "id": 30,
                "consumerId": 130,
                "productId": 231,
                "modelId": 332,
                "productName": "Product DD",
                "modelName": "ModelDD012",
                "firstName": "Evelyn",
                "lastName": "Wright",
                "emailId": "dsryg@gmail.com",
                "phoneNo": "1234567890",
                "address": "2727 Oak Avenue, San Diego, CA",
                "selectedCountry": "South Korea",
                "selectedState": "Busan",
                "selectedCity": "Busan",
                "password": "password30",
                "confirmPassword": "password30",
                "createDateAndTime": "09/10/2024 15:45",
                "updateDateAndTime": "09/10/2024 15:45"
            }
        ]
        
    },

    getConsumersMini() {
        return Promise.resolve(this.getConsumersData().slice(0, 5));
    },

    getConsumersSmall() {
        return Promise.resolve(this.getConsumersData().slice(0, 10));
    },

    getConsumers() {
        return Promise.resolve(this.getConsumersData());
    },

    getConsumersWithOrdersSmall() {
        return Promise.resolve(this.getConsumersWithOrdersData().slice(0, 10));
    },

    getConsumersWithOrders() {
        return Promise.resolve(this.getConsumersWithOrdersData());
    }
};

