export const ServiceEngineer = {
    getServiceEngineersData() {
        return [
            {
                "id": 1,
                "seId": 101,
                "firstName": "Mohan",
                "lastName": "Ramasubbu",
                "dob": "08/12/1999",
                "gender": "Male",
                "emailId": "abc@gmail.com",
                "phoneNo": "9986545890",
                "qualification": "BE",
                "address": "123 Maple Street, New York, NY",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "categories": [
                    { "name": "Category 1" },
                    { "name": "Category 2" },
                    { "name": "Category 3" }
                ],
                "areas": [
                    { "name": "Area 1" },
                    { "name": "Area 2" }
                ],
                "password": "password1",
                "confirmPassword": "password1",
                "createDateAndTime": "08/12/2024 16:23",
                "updateDateAndTime": "08/12/2024 16:23"
            },
            {
                "id": 2,
                "seId": 102,
                "firstName": "Ravi",
                "lastName": "Verma",
                "dob": "11/25/1991",
                "gender": "Male",
                "emailId": "ravi.v@gmail.com",
                "phoneNo": "9876543210",
                "qualification": "MSc",
                "address": "456 Oak Avenue, Los Angeles, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "Los Angeles",
                "categories": [
                    { "name": "Category 4" },
                    { "name": "Category 5" }
                ],
                "areas": [
                    { "name": "Area 3" },
                    { "name": "Area 4" }
                ],
                "password": "password2",
                "confirmPassword": "password2",
                "createDateAndTime": "08/13/2024 14:55",
                "updateDateAndTime": "08/13/2024 14:55"
            },
            {
                "id": 3,
                "seId": 103,
                "firstName": "Anjali",
                "lastName": "Mehta",
                "dob": "03/10/1990",
                "gender": "Female",
                "emailId": "anjali.m@gmail.com",
                "phoneNo": "9876547890",
                "qualification": "MBA",
                "address": "789 Pine Street, Chicago, IL",
                "selectedCountry": "USA",
                "selectedState": "Illinois",
                "selectedCity": "Chicago",
                "categories": [
                    { "name": "Category 6" }
                ],
                "areas": [
                    { "name": "Area 5" },
                    { "name": "Area 6" }
                ],
                "password": "password3",
                "confirmPassword": "password3",
                "createDateAndTime": "08/14/2024 10:30",
                "updateDateAndTime": "08/14/2024 10:30"
            },
            {
                "id": 4,
                "seId": 104,
                "firstName": "Priya",
                "lastName": "Sharma",
                "dob": "05/22/1988",
                "gender": "Female",
                "emailId": "priya.s@gmail.com",
                "phoneNo": "9876556789",
                "qualification": "BTech",
                "address": "321 Elm Street, Houston, TX",
                "selectedCountry": "USA",
                "selectedState": "Texas",
                "selectedCity": "Houston",
                "categories": [
                    { "name": "Category 7" },
                    { "name": "Category 8" }
                ],
                "areas": [
                    { "name": "Area 7" },
                    { "name": "Area 8" }
                ],
                "password": "password4",
                "confirmPassword": "password4",
                "createDateAndTime": "08/15/2024 09:45",
                "updateDateAndTime": "08/15/2024 09:45"
            },
            {
                "id": 5,
                "seId": 105,
                "firstName": "Vikram",
                "lastName": "Singh",
                "dob": "07/19/1992",
                "gender": "Male",
                "emailId": "vikram.s@gmail.com",
                "phoneNo": "9876567890",
                "qualification": "BCom",
                "address": "654 Cedar Avenue, Boston, MA",
                "selectedCountry": "USA",
                "selectedState": "Massachusetts",
                "selectedCity": "Boston",
                "categories": [
                    { "name": "Category 9" }
                ],
                "areas": [
                    { "name": "Area 9" },
                    { "name": "Area 10" }
                ],
                "password": "password5",
                "confirmPassword": "password5",
                "createDateAndTime": "08/16/2024 11:20",
                "updateDateAndTime": "08/16/2024 11:20"
            },
            {
                "id": 6,
                "seId": 106,
                "firstName": "Meena",
                "lastName": "Kumar",
                "dob": "09/15/1985",
                "gender": "Female",
                "emailId": "meena.k@gmail.com",
                "phoneNo": "9876578901",
                "qualification": "MCA",
                "address": "123 Maple Street, New York, NY",
                "selectedCountry": "India",
                "selectedState": "Tamil Nadu",
                "selectedCity": "Coimbatore",
                "categories": [
                    { "name": "Category 10" },
                    { "name": "Category 11" }
                ],
                "areas": [
                    { "name": "Area 11" },
                    { "name": "Area 12" }
                ],
                "password": "password6",
                "confirmPassword": "password6",
                "createDateAndTime": "08/17/2024 14:10",
                "updateDateAndTime": "08/17/2024 14:10"
            },
            {
                "id": 7,
                "seId": 107,
                "firstName": "Rohan",
                "lastName": "Patel",
                "dob": "11/11/1987",
                "gender": "Male",
                "emailId": "rohan.p@gmail.com",
                "phoneNo": "9876589012",
                "qualification": "BE",
                "address": "456 Oak Avenue, Los Angeles, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "Los Angeles",
                "categories": [
                    { "name": "Category 12" }
                ],
                "areas": [
                    { "name": "Area 13" },
                    { "name": "Area 14" }
                ],
                "password": "password7",
                "confirmPassword": "password7",
                "createDateAndTime": "08/18/2024 10:55",
                "updateDateAndTime": "08/18/2024 10:55"
            },
            {
                "id": 8,
                "seId": 108,
                "firstName": "Kavya",
                "lastName": "Iyer",
                "dob": "06/24/1990",
                "gender": "Female",
                "emailId": "kavya.i@gmail.com",
                "phoneNo": "9876590123",
                "qualification": "MBA",
                "address": "789 Pine Street, Chicago, IL",
                "selectedCountry": "USA",
                "selectedState": "Illinois",
                "selectedCity": "Chicago",
                "categories": [
                    { "name": "Category 13" },
                    { "name": "Category 14" }
                ],
                "areas": [
                    { "name": "Area 15" },
                    { "name": "Area 16" }
                ],
                "password": "password8",
                "confirmPassword": "password8",
                "createDateAndTime": "08/19/2024 09:05",
                "updateDateAndTime": "08/19/2024 09:05"
            },
            {
                "id": 9,
                "seId": 109,
                "firstName": "Raj",
                "lastName": "Kohli",
                "dob": "01/10/1993",
                "gender": "Male",
                "emailId": "raj.k@gmail.com",
                "phoneNo": "9876601234",
                "qualification": "MSc",
                "address": "321 Elm Street, Houston, TX",
                "selectedCountry": "USA",
                "selectedState": "Texas",
                "selectedCity": "Houston",
                "categories": [
                    { "name": "Category 15" }
                ],
                "areas": [
                    { "name": "Area 17" },
                    { "name": "Area 18" }
                ],
                "password": "password9",
                "confirmPassword": "password9",
                "createDateAndTime": "08/20/2024 11:00",
                "updateDateAndTime": "08/20/2024 11:00"
            },
            {
                "id": 10,
                "seId": 110,
                "firstName": "Sneha",
                "lastName": "Desai",
                "dob": "03/18/1995",
                "gender": "Female",
                "emailId": "sneha.d@gmail.com",
                "phoneNo": "9876612345",
                "qualification": "BCom",
                "address": "654 Cedar Avenue, Boston, MA",
                "selectedCountry": "USA",
                "selectedState": "Massachusetts",
                "selectedCity": "Boston",
                "categories": [
                    { "name": "Category 16" },
                    { "name": "Category 17" }
                ],
                "areas": [
                    { "name": "Area 19" },
                    { "name": "Area 20" }
                ],
                "password": "password10",
                "confirmPassword": "password10",
                "createDateAndTime": "08/21/2024 13:45",
                "updateDateAndTime": "08/21/2024 13:45"
            },
            {
                "id": 11,
                "seId": 111,
                "firstName": "Arjun",
                "lastName": "Gupta",
                "dob": "04/22/1988",
                "gender": "Male",
                "emailId": "arjun.g@gmail.com",
                "phoneNo": "9876623456",
                "qualification": "MTech",
                "address": "987 Birch Street, San Francisco, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "San Francisco",
                "categories": [
                    { "name": "Category 18" }
                ],
                "areas": [
                    { "name": "Area 21" },
                    { "name": "Area 22" }
                ],
                "password": "password11",
                "confirmPassword": "password11",
                "createDateAndTime": "08/22/2024 10:00",
                "updateDateAndTime": "08/22/2024 10:00"
            },
            {
                "id": 12,
                "seId": 112,
                "firstName": "Nisha",
                "lastName": "Reddy",
                "dob": "07/07/1992",
                "gender": "Female",
                "emailId": "nisha.r@gmail.com",
                "phoneNo": "9876634567",
                "qualification": "PhD",
                "address": "654 Maple Street, Austin, TX",
                "selectedCountry": "USA",
                "selectedState": "Texas",
                "selectedCity": "Austin",
                "categories": [
                    { "name": "Category 19" }
                ],
                "areas": [
                    { "name": "Area 23" },
                    { "name": "Area 24" }
                ],
                "password": "password12",
                "confirmPassword": "password12",
                "createDateAndTime": "08/23/2024 12:30",
                "updateDateAndTime": "08/23/2024 12:30"
            },
            {
                "id": 13,
                "seId": 113,
                "firstName": "Siddharth",
                "lastName": "Jain",
                "dob": "10/05/1991",
                "gender": "Male",
                "emailId": "siddharth.j@gmail.com",
                "phoneNo": "9876645678",
                "qualification": "BTech",
                "address": "123 Cedar Avenue, Seattle, WA",
                "selectedCountry": "USA",
                "selectedState": "Washington",
                "selectedCity": "Seattle",
                "categories": [
                    { "name": "Category 20" }
                ],
                "areas": [
                    { "name": "Area 25" },
                    { "name": "Area 26" }
                ],
                "password": "password13",
                "confirmPassword": "password13",
                "createDateAndTime": "08/24/2024 09:15",
                "updateDateAndTime": "08/24/2024 09:15"
            },
            {
                "id": 14,
                "seId": 114,
                "firstName": "Mehul",
                "lastName": "Patel",
                "dob": "02/14/1994",
                "gender": "Male",
                "emailId": "mehul.p@gmail.com",
                "phoneNo": "9876656789",
                "qualification": "MSc",
                "address": "456 Oak Avenue, Denver, CO",
                "selectedCountry": "USA",
                "selectedState": "Colorado",
                "selectedCity": "Denver",
                "categories": [
                    { "name": "Category 21" }
                ],
                "areas": [
                    { "name": "Area 27" },
                    { "name": "Area 28" }
                ],
                "password": "password14",
                "confirmPassword": "password14",
                "createDateAndTime": "08/25/2024 11:30",
                "updateDateAndTime": "08/25/2024 11:30"
            },
            {
                "id": 15,
                "seId": 115,
                "firstName": "Aarti",
                "lastName": "Kumar",
                "dob": "05/15/1990",
                "gender": "Female",
                "emailId": "aarti.k@gmail.com",
                "phoneNo": "9876667890",
                "qualification": "BE",
                "address": "789 Pine Street, San Diego, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "San Diego",
                "categories": [
                    { "name": "Category 22" }
                ],
                "areas": [
                    { "name": "Area 29" },
                    { "name": "Area 30" }
                ],
                "password": "password15",
                "confirmPassword": "password15",
                "createDateAndTime": "08/26/2024 09:45",
                "updateDateAndTime": "08/26/2024 09:45"
            },
            {
                "id": 16,
                "seId": 116,
                "firstName": "Anil",
                "lastName": "Sharma",
                "dob": "12/30/1989",
                "gender": "Male",
                "emailId": "anil.s@gmail.com",
                "phoneNo": "9876678901",
                "qualification": "BCom",
                "address": "123 Maple Street, Miami, FL",
                "selectedCountry": "USA",
                "selectedState": "Florida",
                "selectedCity": "Miami",
                "categories": [
                    { "name": "Category 23" }
                ],
                "areas": [
                    { "name": "Area 31" },
                    { "name": "Area 32" }
                ],
                "password": "password16",
                "confirmPassword": "password16",
                "createDateAndTime": "08/27/2024 10:00",
                "updateDateAndTime": "08/27/2024 10:00"
            },
            {
                "id": 17,
                "seId": 117,
                "firstName": "Nitin",
                "lastName": "Sethi",
                "dob": "09/18/1991",
                "gender": "Male",
                "emailId": "nitin.s@gmail.com",
                "phoneNo": "9876689012",
                "qualification": "MCA",
                "address": "456 Oak Avenue, Orlando, FL",
                "selectedCountry": "USA",
                "selectedState": "Florida",
                "selectedCity": "Orlando",
                "categories": [
                    { "name": "Category 24" }
                ],
                "areas": [
                    { "name": "Area 33" },
                    { "name": "Area 34" }
                ],
                "password": "password17",
                "confirmPassword": "password17",
                "createDateAndTime": "08/28/2024 11:15",
                "updateDateAndTime": "08/28/2024 11:15"
            },
            {
                "id": 18,
                "seId": 118,
                "firstName": "Kiran",
                "lastName": "Mohan",
                "dob": "03/21/1988",
                "gender": "Female",
                "emailId": "kiran.m@gmail.com",
                "phoneNo": "9876690123",
                "qualification": "MTech",
                "address": "789 Pine Street, Tampa, FL",
                "selectedCountry": "USA",
                "selectedState": "Florida",
                "selectedCity": "Tampa",
                "categories": [
                    { "name": "Category 25" }
                ],
                "areas": [
                    { "name": "Area 35" },
                    { "name": "Area 36" }
                ],
                "password": "password18",
                "confirmPassword": "password18",
                "createDateAndTime": "08/29/2024 09:00",
                "updateDateAndTime": "08/29/2024 09:00"
            },
            {
                "id": 19,
                "seId": 119,
                "firstName": "Divya",
                "lastName": "Nair",
                "dob": "01/12/1994",
                "gender": "Female",
                "emailId": "divya.n@gmail.com",
                "phoneNo": "9876701234",
                "qualification": "BE",
                "address": "321 Elm Street, Phoenix, AZ",
                "selectedCountry": "USA",
                "selectedState": "Arizona",
                "selectedCity": "Phoenix",
                "categories": [
                    { "name": "Category 26" }
                ],
                "areas": [
                    { "name": "Area 37" },
                    { "name": "Area 38" }
                ],
                "password": "password19",
                "confirmPassword": "password19",
                "createDateAndTime": "08/30/2024 10:30",
                "updateDateAndTime": "08/30/2024 10:30"
            },
            {
                "id": 20,
                "seId": 120,
                "firstName": "Sandeep",
                "lastName": "Ghosh",
                "dob": "07/05/1989",
                "gender": "Male",
                "emailId": "sandeep.g@gmail.com",
                "phoneNo": "9876712345",
                "qualification": "MCA",
                "address": "654 Cedar Avenue, Las Vegas, NV",
                "selectedCountry": "USA",
                "selectedState": "Nevada",
                "selectedCity": "Las Vegas",
                "categories": [
                    { "name": "Category 27" }
                ],
                "areas": [
                    { "name": "Area 39" },
                    { "name": "Area 40" }
                ],
                "password": "password20",
                "confirmPassword": "password20",
                "createDateAndTime": "08/31/2024 11:00",
                "updateDateAndTime": "08/31/2024 11:00"
            },
            {
                "id": 21,
                "seId": 121,
                "firstName": "Pooja",
                "lastName": "Rao",
                "dob": "11/01/1993",
                "gender": "Female",
                "emailId": "pooja.r@gmail.com",
                "phoneNo": "9876723456",
                "qualification": "BCom",
                "address": "789 Pine Street, Seattle, WA",
                "selectedCountry": "USA",
                "selectedState": "Washington",
                "selectedCity": "Seattle",
                "categories": [
                    { "name": "Category 28" }
                ],
                "areas": [
                    { "name": "Area 41" },
                    { "name": "Area 42" }
                ],
                "password": "password21",
                "confirmPassword": "password21",
                "createDateAndTime": "09/01/2024 12:15",
                "updateDateAndTime": "09/01/2024 12:15"
            },
            {
                "id": 22,
                "seId": 122,
                "firstName": "Amit",
                "lastName": "Sharma",
                "dob": "02/25/1992",
                "gender": "Male",
                "emailId": "amit.s@gmail.com",
                "phoneNo": "9876734567",
                "qualification": "MSc",
                "address": "123 Maple Street, San Diego, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "San Diego",
                "categories": [
                    { "name": "Category 29" }
                ],
                "areas": [
                    { "name": "Area 43" },
                    { "name": "Area 44" }
                ],
                "password": "password22",
                "confirmPassword": "password22",
                "createDateAndTime": "09/02/2024 09:30",
                "updateDateAndTime": "09/02/2024 09:30"
            },
            {
                "id": 23,
                "seId": 123,
                "firstName": "Neha",
                "lastName": "Kumar",
                "dob": "04/17/1988",
                "gender": "Female",
                "emailId": "neha.k@gmail.com",
                "phoneNo": "9876745678",
                "qualification": "BTech",
                "address": "456 Oak Avenue, Denver, CO",
                "selectedCountry": "USA",
                "selectedState": "Colorado",
                "selectedCity": "Denver",
                "categories": [
                    { "name": "Category 30" }
                ],
                "areas": [
                    { "name": "Area 45" },
                    { "name": "Area 46" }
                ],
                "password": "password23",
                "confirmPassword": "password23",
                "createDateAndTime": "09/03/2024 10:45",
                "updateDateAndTime": "09/03/2024 10:45"
            },
            {
                "id": 24,
                "seId": 124,
                "firstName": "Rajesh",
                "lastName": "Reddy",
                "dob": "08/11/1986",
                "gender": "Male",
                "emailId": "rajesh.r@gmail.com",
                "phoneNo": "9876756789",
                "qualification": "MTech",
                "address": "789 Pine Street, Austin, TX",
                "selectedCountry": "USA",
                "selectedState": "Texas",
                "selectedCity": "Austin",
                "categories": [
                    { "name": "Category 31" }
                ],
                "areas": [
                    { "name": "Area 47" },
                    { "name": "Area 48" }
                ],
                "password": "password24",
                "confirmPassword": "password24",
                "createDateAndTime": "09/04/2024 11:00",
                "updateDateAndTime": "09/04/2024 11:00"
            },
            {
                "id": 25,
                "seId": 125,
                "firstName": "Sonia",
                "lastName": "Gupta",
                "dob": "12/18/1991",
                "gender": "Female",
                "emailId": "sonia.g@gmail.com",
                "phoneNo": "9876767890",
                "qualification": "MCA",
                "address": "321 Elm Street, Phoenix, AZ",
                "selectedCountry": "USA",
                "selectedState": "Arizona",
                "selectedCity": "Phoenix",
                "categories": [
                    { "name": "Category 32" }
                ],
                "areas": [
                    { "name": "Area 49" },
                    { "name": "Area 50" }
                ],
                "password": "password25",
                "confirmPassword": "password25",
                "createDateAndTime": "09/05/2024 12:30",
                "updateDateAndTime": "09/05/2024 12:30"
            },
            {
                "id": 26,
                "seId": 126,
                "firstName": "Aman",
                "lastName": "Saini",
                "dob": "06/30/1990",
                "gender": "Male",
                "emailId": "aman.s@gmail.com",
                "phoneNo": "9876778901",
                "qualification": "BCom",
                "address": "654 Cedar Avenue, San Francisco, CA",
                "selectedCountry": "USA",
                "selectedState": "California",
                "selectedCity": "San Francisco",
                "categories": [
                    { "name": "Category 33" }
                ],
                "areas": [
                    { "name": "Area 51" },
                    { "name": "Area 52" }
                ],
                "password": "password26",
                "confirmPassword": "password26",
                "createDateAndTime": "09/06/2024 10:45",
                "updateDateAndTime": "09/06/2024 10:45"
            },
            {
                "id": 27,
                "seId": 127,
                "firstName": "Ishaan",
                "lastName": "Nair",
                "dob": "05/25/1992",
                "gender": "Male",
                "emailId": "ishaan.n@gmail.com",
                "phoneNo": "9876789012",
                "qualification": "MTech",
                "address": "123 Maple Street, Seattle, WA",
                "selectedCountry": "USA",
                "selectedState": "Washington",
                "selectedCity": "Seattle",
                "categories": [
                    { "name": "Category 34" }
                ],
                "areas": [
                    { "name": "Area 53" },
                    { "name": "Area 54" }
                ],
                "password": "password27",
                "confirmPassword": "password27",
                "createDateAndTime": "09/07/2024 11:30",
                "updateDateAndTime": "09/07/2024 11:30"
            },
            {
                "id": 28,
                "seId": 128,
                "firstName": "Maya",
                "lastName": "Rao",
                "dob": "04/20/1991",
                "gender": "Female",
                "emailId": "maya.r@gmail.com",
                "phoneNo": "9876790123",
                "qualification": "BE",
                "address": "456 Oak Avenue, Denver, CO",
                "selectedCountry": "USA",
                "selectedState": "Colorado",
                "selectedCity": "Denver",
                "categories": [
                    { "name": "Category 35" }
                ],
                "areas": [
                    { "name": "Area 55" },
                    { "name": "Area 56" }
                ],
                "password": "password28",
                "confirmPassword": "password28",
                "createDateAndTime": "09/08/2024 09:00",
                "updateDateAndTime": "09/08/2024 09:00"
            },
            {
                "id": 29,
                "seId": 129,
                "firstName": "Raj",
                "lastName": "Saini",
                "dob": "11/18/1989",
                "gender": "Male",
                "emailId": "raj.s@gmail.com",
                "phoneNo": "9876801234",
                "qualification": "BTech",
                "address": "789 Pine Street, Austin, TX",
                "selectedCountry": "USA",
                "selectedState": "Texas",
                "selectedCity": "Austin",
                "categories": [
                    { "name": "Category 36" }
                ],
                "areas": [
                    { "name": "Area 57" },
                    { "name": "Area 58" }
                ],
                "password": "password29",
                "confirmPassword": "password29",
                "createDateAndTime": "09/09/2024 10:30",
                "updateDateAndTime": "09/09/2024 10:30"
            },
            {
                "id": 30,
                "seId": 130,
                "firstName": "Ananya",
                "lastName": "Sethi",
                "dob": "10/22/1990",
                "gender": "Female",
                "emailId": "ananya.s@gmail.com",
                "phoneNo": "9876812345",
                "qualification": "MCA",
                "address": "321 Elm Street, Phoenix, AZ",
                "selectedCountry": "USA",
                "selectedState": "Arizona",
                "selectedCity": "Phoenix",
                "categories": [
                    { "name": "Category 37" }
                ],
                "areas": [
                    { "name": "Area 59" },
                    { "name": "Area 60" }
                ],
                "password": "password30",
                "confirmPassword": "password30",
                "createDateAndTime": "09/10/2024 11:00",
                "updateDateAndTime": "09/10/2024 11:00"
            }           
        ]
        
    },

    getServiceEngineersMini() {
        return Promise.resolve(this.getServiceEngineersData().slice(0, 5));
    },

    getServiceEngineersSmall() {
        return Promise.resolve(this.getServiceEngineersData().slice(0, 10));
    },

    getServiceEngineers() {
        return Promise.resolve(this.getServiceEngineersData());
    },

    getServiceEngineersWithOrdersSmall() {
        return Promise.resolve(this.getServiceEngineersWithOrdersData().slice(0, 10));
    },

    getServiceEngineersWithOrders() {
        return Promise.resolve(this.getServiceEngineersWithOrdersData());
    }
};

