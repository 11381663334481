import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Draggable } from '@fullcalendar/interaction';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import Base from './Base'
// Sample data
const engineers = [
    { id: 1, name: 'Alice', events: [
        { title: 'Alice Event 1', date: '2024-08-11' },
        { title: 'Alice Event 2', date: '2024-08-12' }
      ]
    },
    { id: 2, name: 'Bob', events: [
        { title: 'Bob Event 1', date: '2024-08-15' },
        { title: 'Bob Event 2', date: '2024-08-16' }
      ]
    }
  ];
  

const TicketTest = () => {

    const calendarRef = useRef(null);
    const externalEventsRef = useRef(null);
    const [selectedEngineer, setSelectedEngineer] = useState(engineers[0].id);
    const [events, setEvents] = useState(engineers[0].events);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [tickets, setTickets] = useState([
      { id: 1, title: 'Ticket 1', color: 'grey' },
      { id: 2, title: 'Ticket 2', color: 'grey' },
      { id: 3, title: 'Ticket 3', color: 'grey' }
    ]);
  
    useEffect(() => {
      if (externalEventsRef.current) {
        new Draggable(externalEventsRef.current, {
          itemSelector: '.fc-event',
          eventData: (eventEl) => ({
            title: eventEl.innerText,
          }),
        });
      }
    }, []);
  
    useEffect(() => {
      const selectedEngineerData = engineers.find(e => e.id === selectedEngineer);
      if (selectedEngineerData) {
        setEvents(selectedEngineerData.events);
      }
    }, [selectedEngineer]);
  
    const handleEventReceive = (info) => {
      console.log('Event dropped on calendar:', info.event);
      const event = { title: info.event.title, date: info.event.startStr };
      setEvents([...events, event]);
  
      // Remove the ticket from the panel after being dropped
      const updatedTickets = tickets.filter(ticket => ticket.title !== info.event.title);
      setTickets(updatedTickets);
    };
  
    const handleEventClick = (info) => {
      setSelectedEvent(info.event);
      setModalIsOpen(true);
    };
  
    const handleModalClose = () => {
      setModalIsOpen(false);
      setSelectedEvent(null);
      setSelectedTicket(null);
    };
  
    const handleEngineerChange = (event) => {
      setSelectedEngineer(parseInt(event.target.value, 10));
    };
  
    const handleTicketClick = (ticket) => {
      setSelectedTicket(ticket);
      setModalIsOpen(true);
    };

  return (
    <Base>
      <div style={{ display: 'flex' }}>
      <div
        ref={externalEventsRef}
        id="external-events"
        style={{
          width: '200px',
          padding: '10px',
          borderRight: '1px solid #ddd',
          boxSizing: 'border-box'
        }}
      >
        <h3>Open Tickets</h3>
        {tickets.map(ticket => (
          <div
            key={ticket.id}
            className="fc-event"
            style={{ margin: '5px', padding: '10px', background: ticket.color, color: 'white', cursor: 'pointer' }}
            onClick={() => handleTicketClick(ticket)}
          >
            {ticket.title}
          </div>
        ))}
      </div>
      
      <div style={{ flex: 1, padding: '10px' }}>
  <h3>Select Engineers</h3>
  <select
    value={selectedEngineer}
    onChange={handleEngineerChange}
    style={{ 
      width: '100%', // Make the select dropdown full width
      marginBottom: '10px', 
      padding: '5px',
      backgroundColor: 'gray', // Set background color to gray
      color: 'white', // Set text color to white
      border: '1px solid #ccc', // Optional: Add border for better appearance
      borderRadius: '4px' // Optional: Add rounded corners
    }}
  >
    {engineers.map(engineer => (
      <option key={engineer.id} value={engineer.id}>
        {engineer.name}
      </option>
    ))}
  </select>
  <FullCalendar
    ref={calendarRef}
    plugins={[dayGridPlugin, interactionPlugin]}
    editable={true}
    droppable={true}
    events={events}
    eventReceive={handleEventReceive}
    eventClick={handleEventClick}
  />
</div>


      <Modal show={modalIsOpen} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <p><strong>Title:</strong> {selectedEvent.title}</p>
              <p><strong>Date:</strong> {selectedEvent.startStr}</p>
            </div>
          )}
          {selectedTicket && !selectedEvent && (
            <div>
              <p><strong>Title:</strong> {selectedTicket.title}</p>
              <p><strong>Color:</strong> {selectedTicket.color}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </Base>

  );
};

export default TicketTest