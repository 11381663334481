import ConsumerBase from "./ConsumerBase";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ticket } from "./service/Ticket";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea"; // Corrected import
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "./styles.css"; //

const ConsumerMyTicket = () => {
  const emptyTicket = {
    id: null,
    ticketRaiseUserId: null,
    consumerId: null,
    productId: null,
    modelId: null,
    ticketDescription: "",
    consumerAvailableContactDateAndTime: null,
    serviceExpectedCompletionDateAndTime: null,
    assignedSE: "",
    serviceAssignedDateAndTime: null,
    address: "",
    productImages: "",
    serviceCompletedDateAndTime: null,
    ticketStatus: "",
    createDateAndTime: null,
    updateDateAndTime: null,
  };
  const [ticket, setTicket] = useState(emptyTicket);

  const [selectedDescription, setSelectedDescription] = useState(
    ticket.ticketDescription
  );
  const [tickets, setTickets] = useState([]);
  const [ticketDialog, setTicketDialog] = useState(false);
  const [deleteTicketDialog, setDeleteTicketDialog] = useState(false);
  const [deleteTicketsDialog, setDeleteTicketsDialog] = useState(false);
  const [reorderColumnsDialog, setReorderColumnsDialog] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedImage, setSelectedImage] = useState("/assets/images/logo.png"); // Default image path
  const [submitted, setSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    ticket.selectedCountry || ""
  );
  const [selectedState, setSelectedState] = useState(
    ticket.selectedState || ""
  );
  const [selectedCity, setSelectedCity] = useState(ticket.selectedCity || "");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const ticketRemark = ticket.ticketRemark || "";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [availableModels, setAvailableModels] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [date, setDate] = useState(null);

  const toggleFilters = () => {
    setFiltersVisible((prevState) => !prevState);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ticketRaiseUserId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    consumerId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    productId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modelId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ticketDescription: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ticketRemark: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    consumerAvailableContactDateAndTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    serviceExpectedCompletionDateAndTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    assignedSE: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    serviceAssignedDateAndTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCountry: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedState: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    selectedCity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    serviceCompletedDateAndTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ticketStatus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    
  });

  const productData = [
    {
      productId: "434",
      modelId: "721",
      productName: "Product A",
      models: [
        { modelId: "721", modelName: "Model 12sc" },
        { modelId: "722", modelName: "Model 15xx" }
      ],
      ticketStatus: "Open"
    },
    {
      productId: "302",
      modelId: "723",
      productName: "Product B",
      models: [
        { modelId: "723", modelName: "Model 18gh" },
        { modelId: "724", modelName: "Model 20zz" }
      ],
      ticketStatus: "Open"
    },
  ];
  
  useEffect(() => {
    // Load states when selectedCountry changes
    if (selectedCountry) {
      const countryData = data.countries.find(
        (country) => country.name === selectedCountry
      );
      setStates(countryData ? countryData.states : []);
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Load cities when selectedState changes
    if (selectedState) {
      const stateData = states.find((state) => state.name === selectedState);
      setCities(stateData ? stateData.cities : []);
    }
  }, [selectedState, states]);

  const [availableColumns, setAvailableColumns] = useState([
    { label: "Ticket Raise User ID", id: "ticketRaiseUserId" },
    { label: "Consumer ID", id: "consumerId" },
    { label: "Product ID", id: "productId" },
    { label: "Product Name", id: "productName" },
    { label: "Model ID", id: "modelId" },
    { label: "Model Name", id: "modelName" },
    { label: "Ticket Description", id: "ticketDescription" },
    { label: "Ticket Remark", id: "ticketRemark" },
    {
      label: "Consumer Available Contact Date and Time",
      id: "consumerAvailableContactDateAndTime",
    },
    {
      label: "Service Expected Completion Date and Time",
      id: "serviceExpectedCompletionDateAndTime",
    },
    { label: "Assigned SE", id: "assignedSE" },
    {
      label: "Service Assigned Date and Time",
      id: "serviceAssignedDateAndTime",
    },
    { label: "Address", id: "address" },
    { label: "Country", id: "selectedCountry" },
    { label: "State", id: "selectedState" },
    { label: "City", id: "selectedCity" },
    
    {
      label: "Service Completed Date and Time",
      id: "serviceCompletedDateAndTime",
    },
    { label: "Ticket Status", id: "ticketStatus" },
  ]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    Ticket.getTickets().then((data) => setTickets(data));
    setSelectedColumns(availableColumns);
    setSource(availableColumns);
  }, []);

  const onDateChange = (e, fieldName) => {
    setTicket({
      ...ticket,
      [fieldName]: e.value,
    });
  };

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDescriptionChange = (e) => {
    setSelectedDescription(e.value);
    onInputChange({ target: { value: e.value } }, "ticketDescription");
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setTicket((prevTicket) => ({
      ...prevTicket,
      selectedCountry: e.target.value, // Update the productImage field in the ticket state
    }));

    setSelectedState(""); // Reset state and city when country changes
    setSelectedCity("");
  };

  const handleUpload = (e) => {
    const files = e.target.files; // Access files from the target of the event
    if (files && files.length > 0) {
      const imageUrl = URL.createObjectURL(files[0]); // Get the selected image URL
      setTicket((prevTicket) => ({
        ...prevTicket,
        productImage: imageUrl, // Update the productImage field in the ticket state
      }));
    }
    onInputChange(
      { target: { id: "productImages", value: files } },
      "productImages"
    );
  };

  const handleInputChange = (e, field) => {
    setTicket((prevTicket) => ({
      ...prevTicket,
      [field]: e.target.value, // Update other fields in the ticket state
    }));
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    
    setTicket((prevTicket) => ({
      ...prevTicket,
      selectedState: e.target.value, // Update the productImage field in the ticket state
    }));
    setSelectedCity(""); // Reset city when state changes
  };

  const handleColumnChange = (e) => {
    setSource(e.source);
    setTarget(e.target);
  };

  const saveTicket = () => {
    setSubmitted(true);

    let _tickets = [...tickets];
    let _ticket = { ...ticket };

    if (ticket.id) {
      const index = _tickets.findIndex((t) => t.id === ticket.id);
      _tickets[index] = _ticket;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Ticket Updated",
        life: 3000,
      });
    } else {
      _ticket.id = new Date().getTime(); // Unique ID generator
      _tickets.push(_ticket);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Ticket Created",
        life: 3000,
      });
    }

    setTickets(_tickets);
    setTicketDialog(false);
    setTicket(emptyTicket);
  };

   const imageBodyTemplate = (rowData) => {
        return (
            <img
                src={`/assets/images/${rowData.productImages}`}
                alt={rowData.productImages}
                className="shadow-2 border-round"
                style={{ width: '64px' }}
            />
        );
    };
  const deleteTicket = () => {
    let _tickets = tickets.filter((val) => val.id !== ticket.id);
    setTickets(_tickets);
    setDeleteTicketDialog(false);
    setTicket(emptyTicket);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Ticket Deleted",
      life: 3000,
    });
  };

  const deleteSelectedTickets = () => {
    // Use unique identifiers for comparison
    const selectedIds = new Set(selectedTickets.map(ticket => ticket.id));
    const updatedTickets = tickets.filter(ticket => !selectedIds.has(ticket.id));
    
    setTickets(updatedTickets);
    setDeleteTicketsDialog(false);
    setSelectedTickets([]);
    
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Tickets Deleted",
      life: 3000,
    });
};

  const onSelectionChange = (e) => {
    setSelectedTickets(e.value);
  };

  const editTicket = (rowData) => {
    setTicket({ ...rowData });
    setSelectedCountry(rowData.selectedCountry || "");
    setSelectedState(rowData.selectedState || "");
    setSelectedCity(rowData.selectedCity || "");
    setTicketDialog(true);
  };
  const data = {
    countries: [
      {
        name: "India",
        states: [
          { name: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
          { name: "Karnataka", cities: ["Bengaluru", "Mysuru"] },
        ],
      },
      {
        name: "USA",
        states: [
          { name: "California", cities: ["Los Angeles", "San Francisco"] },
          { name: "New York", cities: ["New York City", "Buffalo"] },
        ],
      },
    ],
  };

  

  const ticketDescriptions = [
    { label: "Noise problem", value: "Noise problem" },
    { label: "Leakage issue", value: "Leakage issue" },
    { label: "Cooling issue", value: "Cooling issue" },
    { label: "Leakage issue", value: "Leakage issue" },
    { label: "Spinning issue", value: "Spinning issue" },
    { label: "Door sealing issue", value: "Door sealing issue" },
    { label: "Remote control issue", value: "Remote control issue" },
    { label: "Odor issue", value: "Odor issue" },
  ];

  const rowActionsBodyTemplate = (rowData) => {
    // Check if the ticket status allows for actions
    const isStatusOpen = rowData.ticketStatus === "Open"; // Adjust this condition based on your status criteria
    return (
      <div className="actions">
        {isStatusOpen && (
          <Button
            icon="pi pi-pencil"
            className="custom-button-edit p-button-rounded p-button-sm"
            onClick={() => editTicket(rowData)}
          />
        )}
        {isStatusOpen && (
          <Button
            icon="pi pi-trash"
            className="custom-button-delete p-button-rounded p-button-sm"
            onClick={() => {
              setTicket(rowData);
              setDeleteTicketDialog(true);
            }}
          />
        )}
      </div>
    );
  };


  const handleProductChange = (event) => {
    const selectedProductId = event.target.value;
    const selectedProduct = productData.find(p => p.productId === selectedProductId);

    if (selectedProduct) {
      setSelectedProduct(selectedProduct.productName);
      setAvailableModels(selectedProduct.models);
      setTicket(prevTicket => ({
        ...prevTicket,
        productId: selectedProduct.productId,
        productName: selectedProduct.productName,
        modelId: selectedProduct.modelId,
        models: selectedProduct.models
      }));
    }
  };

  const openReorder = () => {
    setReorderColumnsDialog(true);
  };

  const newTicket = () => {
    setTicket(emptyTicket);
    setTicketDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          className="button-new"
          onClick={newTicket}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="button-delete"
          onClick={() => setDeleteTicketsDialog(true)}
          disabled={!selectedTickets.length} // Disable if no tickets selected
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="button-export mx-2"
          onClick={() => dt.current.exportCSV()}
        />
        <Button
          label="Reorder Columns"
          icon="pi pi-sort"
          className="button-reorder mx-2"
          onClick={openReorder}
        />
        <Button className="button-filters" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
    );
  };

  const header = (
    <div className="flex items-center justify-between gap-2">
  <h4 className="m-0">My Tickets</h4>
  <div className="flex items-center p-input-icon p-input-icon-left">
    
    <InputText
      type="search"
      onInput={(e) => setGlobalFilterValue(e.target.value)}
      placeholder="Search..."
      className="ml-2"
    />
  </div>
</div>
  );

  const ticketDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => setTicketDialog(false)}
        className="cancel-button" // Apply CSS class for Cancel button
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={saveTicket}
        className="edit-button" // Apply CSS class for Edit button
      />
    </React.Fragment>
  );

  const deleteTicketDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteTicketDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteTicket} />
    </React.Fragment>
  );

  const deleteTicketsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteTicketsDialog(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteSelectedTickets} />
    </React.Fragment>
  );
  // ===================================SAVE REORDER COMPONENTS==========================================
  const saveReorderColumn = () => {
    setSelectedColumns(target);
    setReorderColumnsDialog(false);
  };

  const reorderColumnsDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        className="reorder-cancel-button"
        onClick={() => setReorderColumnsDialog(false)}
      />
      <Button label="Save" icon="pi pi-check" 
      className="reorder-save-button"
      onClick={saveReorderColumn} />
    </React.Fragment>
  );
  // ===================================END OF SAVE REORDER COMPONENTS==========================================
  const onInputChange = (e, field) => {
    const val = e.target.value;
    setTicket({ ...ticket, [field]: val });
  };

  const productItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span>{option.productName} - {option.modelName} </span>
      </div>
    );
  };
  

  // ===================================VIEW COMPONENTS==========================================
  return (
    <ConsumerBase>
      <div>
        <Toast ref={toast} />

        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />

        {selectedColumns && selectedColumns.length > 0 ? (
          <DataTable
          ref={dt}
          value={tickets.map((ticket) => ({
            ...ticket,
            consumerAvailableContactDateAndTime: ticket.consumerAvailableContactDateAndTime
              ? new Date(ticket.consumerAvailableContactDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
            serviceExpectedCompletionDateAndTime: ticket.serviceExpectedCompletionDateAndTime
              ? new Date(ticket.serviceExpectedCompletionDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
            serviceAssignedDateAndTime: ticket.serviceAssignedDateAndTime
              ? new Date(ticket.serviceAssignedDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
            serviceCompletedDateAndTime: ticket.serviceCompletedDateAndTime
              ? new Date(ticket.serviceCompletedDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
            createDateAndTime: ticket.createDateAndTime
              ? new Date(ticket.createDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
            updateDateAndTime: ticket.updateDateAndTime
              ? new Date(ticket.updateDateAndTime).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null,
          }))}
          selection={selectedTickets}
          onSelectionChange={onSelectionChange}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          header={header}
          globalFilter={globalFilterValue}
            filters={filtersVisible ? filters : null}
            filterDisplay={filtersVisible ? "row" : "none"}
          emptyMessage="No tickets found."
        >
          <Column selectionMode="multiple" exportable={false} />
          {selectedColumns.map((col) => (
            <Column key={col.id} field={col.id} header={col.label} 
            sortable
            filter
            filterPlaceholder="Search"
            style={{ minWidth: "12rem", textAlign: "left" }}
            />
          ))}
          <Column
            body={imageBodyTemplate}
            header="Product Image"
            style={{ width: '100px' }}
          />
          <Column
            body={rowActionsBodyTemplate}
            exportable={false}
            className="mx-2"
            style={{ minWidth: "12rem" }}
          />
        </DataTable>
        ) : (
          <div className="card">
            <h4 style={{ textAlign: "center", padding: "1rem" }}>
              No Columns have been selected{" "}
            </h4>
          </div>
        )}

        <Dialog
          visible={ticketDialog}
          style={{ width: "700px" }}
          header={ticket.id ? "Edit Ticket Details" : "Create Ticket Details"}
          modal
          className="p-fluid dialog custom-dialog-header"
          footer={ticketDialogFooter}
          onHide={() => setTicketDialog(false)}
        >
          <div className="form-grid">
            {ticket.id && (
              <>
                <div className="field">
                  <label htmlFor="ticketRaiseUserId" className="bold-label">
                    Ticket Raise User ID
                  </label>
                  <InputText
                    id="ticketRaiseUserId"
                    value={ticket.ticketRaiseUserId}
                    disabled // Make the field non-editable
                    className={classNames({
                      "p-invalid": submitted && !ticket.ticketRaiseUserId,
                    })}
                  />
                  {submitted && !ticket.ticketRaiseUserId && (
                    <small className="p-error">
                      Ticket Raise User ID is required.
                    </small>
                  )}
                </div>

                <div className="field">
                  <label htmlFor="consumerId" className="bold-label">
                    Consumer ID
                  </label>
                  <InputText
                    id="consumerId"
                    value={ticket.consumerId}
                    disabled // Make the field non-editable
                  />
                </div>
              </>
            )}
          </div>

          <div className="field">
          {ticket.id && (
              <>
            <label htmlFor="ticketStatus" className="bold-label">
              Ticket Status
            </label>
            <InputText
              id="ticketStatus"
              value={ticket.ticketStatus}
              onChange={(e) => onInputChange(e, "ticketStatus")}
              disabled // Make the field non-editable
              className="full-width" // Add a class for full-width styling
            />
          <hr></hr>
          </>
          )}
          </div>
          <div className="form-grid">
          <div className="field">
  <label htmlFor="productId" className="bold-label">
    Product Name
  </label>
  <select onChange={handleProductChange}>
    <option value="">Select a Product</option>
    {productData.map((product) => (
      <option key={product.productId} value={product.productId}>
        {product.productName}
      </option>
    ))}
  </select>
</div>

<div className="field">
            <label htmlFor="modelId" className="bold-label">Model Name</label>
            <select id="modelId">
              <option value="">Select a Model</option>
              {availableModels.map(model => (
                <option key={model.modelId} value={model.modelId}>{model.modelName}</option>
              ))}
            </select>
          </div>

            <div className="field">
              <label htmlFor="ticketDescription" className="bold-label">
                Ticket Description
              </label>
              <Dropdown
                id="ticketDescription"
                value={ticket.ticketDescription}
                options={ticketDescriptions}
                onChange={handleDescriptionChange}
                placeholder="Select a description"
                required
                className={classNames({
                  "p-invalid": submitted && !selectedDescription,
                })}
              />
              
            </div>

            <div className="field">
              <label htmlFor="ticketRemark" className="bold-label">
                Ticket Remark
              </label>
              <div className="input-container">
                <InputText
                  id="ticketRemark"
                  value={ticketRemark}
                  onChange={(e) => onInputChange(e, "ticketRemark")}
                  className={classNames({
                    "p-invalid": submitted && !ticketRemark,
                  })}
                  style={{
                    whiteSpace: "nowrap",
                    overflowX: "auto",
                    textOverflow: "ellipsis",
                  }}
                />
              </div>
              {ticketRemark.length > 100 && (
                <button
                  onClick={handleReadMoreClick}
                  className="read-more-button"
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              )}
              
            </div>
            </div>
            <hr></hr>

            <div className="form-grid">

            <div className="field">
  <label
    htmlFor="consumerAvailableContactDateAndTime"
    className="bold-label"
  >
    Available Contact Date & Time
  </label>
  <Calendar 
    id="buttondisplay" 
    value={date} 
    onChange={(e) => setDate(e.value)} 
    showIcon 
    className="custom-calendar" // Add your custom class here
  />
</div>

            <div className="field">
              <label htmlFor="address" className="bold-label">
                Address
              </label>
              <InputText
                id="address"
                value={ticket.address}
                onChange={(e) => onInputChange(e, "address")}
              />
            </div>

            <div className="field">
              <label htmlFor="country" className="bold-label">
                Country
              </label>
              <select
                id="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {data.countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="field">
              <label htmlFor="state" className="bold-label">
                State
              </label>
              <select
                id="state"
                value={selectedState}
                onChange={handleStateChange}
                disabled={!selectedCountry}
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="field">
              <label htmlFor="city" className="bold-label">
                City
              </label>
              <select
                id="city"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedState}
              >
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
          <div className="field">
      <label htmlFor="productImages" className="bold-label">
        Product Images
      </label>

      <img
        src={ticket.productImages || "/assets/images/logo.png"} // Display the selected image or a default one
        alt="Selected"
        style={{ maxWidth: "100px", marginBottom: "10px" }}
      />
      <FileUpload
        name="demo[]"
        url="/images"
        multiple
        accept="image/*"
        maxFileSize={1000000}
        emptyTemplate={
          <p className="m-0">Drag and drop files here to upload.</p>
        }
        onUpload={handleUpload}
      />

      
    </div>
        </Dialog>

        <Dialog
          visible={deleteTicketDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteTicketDialogFooter}
          onHide={() => setDeleteTicketDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {ticket && (
              <span>
                Are you sure you want to delete{" "}
                <b>{ticket.ticketRaiseUserId}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteTicketsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteTicketsDialogFooter}
          onHide={() => setDeleteTicketsDialog(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to delete the selected tickets?</span>
          </div>
        </Dialog>

        <Dialog
          visible={reorderColumnsDialog}
          style={{ width: "800px" }}
          header="Reorder Columns"
          modal
          footer={reorderColumnsDialogFooter}
          onHide={() => setReorderColumnsDialog(false)}
        >
          <PickList
            source={source}
            target={target}
            onChange={handleColumnChange}
            itemTemplate={(item) => item.label}
            sourceHeader="Available Columns"
            targetHeader="Selected Columns"
          />
        </Dialog>
      </div>
    </ConsumerBase>
  );
};

export default ConsumerMyTicket;
