import GoogleMapReact from "google-map-react";
import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "primereact/resources/primereact.min.css";

const ConsumerBase = ({ children }) => {
  const defaultProps = {
    center: {
      lat: 11.0176,
      lng: 76.9674,
    },
    zoom: 12,
  };

  const AnyReactComponent = ({ text }) => (
    <div>
      <img src={text} alt="Map Marker" />
    </div>
  );

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>ServizPoint</title>
        <link rel="stylesheet" href="../assets/vendors/feather/feather.css" />
        <link
          rel="stylesheet"
          href="../assets/vendors/ti-icons/css/themify-icons.css"
        />
        <link
          rel="stylesheet"
          href="../assets/vendors/css/vendor.bundle.base.css"
        />
        <link
          rel="stylesheet"
          href="../assets/vendors/mdi/css/materialdesignicons.min.css"
        />
        <link rel="stylesheet" href="../assets/calendar/css/style.css" />
        <link
          rel="stylesheet"
          href="../assets/vendors/datatables.net-bs5/dataTables.bootstrap5.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="../assets/js/select.dataTables.min.css"
        />
        <link rel="stylesheet" href="../assets/css/style.css" />
        <link rel="shortcut icon" href="../assets/images/favicon.png" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.2.0/main.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.3.0/main.min.css"
        />
      </Helmet>

      <div className="container-scroller">
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
            <a className="navbar-brand brand-logo me-5" href="index.html">
              <img src="../assets/images/logo.png" className="me-2" alt="logo" />
            </a>
            <a className="navbar-brand brand-logo-mini" href="index.html">
              <img src="../assets/images/logo.png" alt="logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-bs-toggle="minimize"
            >
              <span className="icon-menu"></span>
            </button>
            <ul className="navbar-nav mr-lg-2">
              <li className="nav-item nav-search d-none d-lg-block">
                <div className="input-group">
                  <div
                    className="input-group-prepend hover-cursor"
                    id="navbar-search-icon"
                  ></div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item dropdown">
                <a
                  className="nav-link count-indicator dropdown-toggle"
                  id="notificationDropdown"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="icon-bell mx-0"></i>
                  <span className="count"></span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="notificationDropdown"
                >
                  <p className="mb-0 font-weight-normal float-left dropdown-header">
                    Notifications
                  </p>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="ti-info-alt mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        Application Error
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Just now
                      </p>
                    </div>
                  </a>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="ti-settings mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        Settings
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Private message
                      </p>
                    </div>
                  </a>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="ti-user mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        New user registration
                      </h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        2 days ago
                      </p>
                    </div>
                  </a>
                </div>
              </li>
              <li className="nav-item nav-profile dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  id="profileDropdown"
                >
                  <img src="../assets/images/faces/face28.jpg" alt="profile" />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="profileDropdown"
                >
                  <a className="dropdown-item">
                    <i className="ti-settings text-primary"></i> Settings
                  </a>
                  <a className="dropdown-item">
                    <i className="ti-power-off text-primary"></i> Logout
                  </a>
                </div>
              </li>
              <li className="nav-item nav-settings d-none d-lg-flex">
                <a className="nav-link" href="#">
                  <i className="icon-ellipsis"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="container-fluid page-body-wrapper">
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/consumer/dashboard">
                  <i className="icon-grid menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/consumer/products">
                  <i className="fa fa-address-book menu-icon" aria-hidden="true"></i>
                  <span className="menu-title">My Products</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/consumer/myTicket">
                  <i className="fa fa-address-book menu-icon" aria-hidden="true"></i>
                  <span className="menu-title">My Tickets</span>
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="main-panel">
            <div className="content-wrapper">{children}</div>
            <footer className="footer">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Copyright © 2024. ServizPoint. All rights reserved.
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>

      <script src="../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../assets/vendors/chart.js/chart.umd.js"></script>
      <script src="../assets/vendors/datatables.net/jquery.dataTables.js"></script>
      <script src="../assets/vendors/datatables.net-bs5/dataTables.bootstrap5.js"></script>
      <script src="../assets/js/dataTables.select.min.js"></script>
      <script src="../assets/js/off-canvas.js"></script>
      <script src="../assets/js/template.js"></script>
      <script src="../assets/js/settings.js"></script>
      <script src="../assets/js/todolist.js"></script>
      <script src="../assets/js/jquery.cookie.js" type="text/javascript"></script>
      <script src="../assets/js/dashboard.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.2.0/main.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/@fullcalendar/interaction@4.3.0/main.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.3.0/main.min.js"></script>
    </div>
  );
};

export default ConsumerBase;
